import Vue from 'vue';
import OrganizationsHttp from '@/modules/business/services/http/organizations/OrganizationsService';
import WhiteLabelService from '@/modules/business/services/http/organizations/WhiteLabelService';
import LocalStorage from '@/services/LocalStorage';

const organizationLocalStorage = LocalStorage.get('organization');

export default {
  namespaced: true,
  state: {
    items: [],
    selected: organizationLocalStorage,
    personalWorkspace: false,
  },
  getters: {
    getOrganizationById: (state) => (id) =>
      state.items.filter((item) => item.id === id).shift(),
    getIsOwner: (state) => {
      return state.selected.is_owner;
    },
  },
  actions: {
    getItems: ({ commit }) =>
      OrganizationsHttp.get().then((result) => {
        commit('setItems', result.data.data);
      }),
    updateItem: ({ commit }, params) => {
      const { id, name, logo } = params;
      return OrganizationsHttp.patch(id, { name, logo }).then((result) => {
        commit('updateItem', result.data);
        return result;
      });
    },
    createItem: ({ commit }, params) => {
      const { name } = params;
      return OrganizationsHttp.create({ name }).then((result) => {
        commit('addItem', result.data);
        return result;
      });
    },
    removeItem: ({ commit }, params) => {
      const { id, password } = params;
      return OrganizationsHttp.delete(id, { password }).then(() => {
        commit('removeItem', id);
      });
    },
    setSelected: ({ commit }, organization) => {
      LocalStorage.set('organization', organization.id);
      commit('setSelected', organization);
    },
    getSelected: async ({ commit }) => {
      const organizationId = LocalStorage.get('organization');
      if (organizationId !== 'undefined' && organizationId !== false) {
        const organization = await OrganizationsHttp.get(organizationId, {
          extended: true,
        });
        const palette = await WhiteLabelService.list(organizationId);
        organization.data.style = palette.data;
        commit('setSelected', organization.data);
      }
    },
  },
  mutations: {
    setSelected: (state, organization) => {
      state.selected = organization;
    },
    setOrganizationLogo: (state, logo) => {
      state.selected.logo = logo;
    },
    addItem: (state, data) => {
      state.items.push(data);
    },
    setItems: (state, items) => {
      state.items = items;
    },
    updateItem: (state, data) => {
      state.items.forEach((item, index) => {
        if (item.id === data.id) {
          Vue.set(state.items, index, data);
        }
      });
    },
    removeItem: (state, id) => {
      state.items.forEach((item, index) => {
        if (item.id === id) {
          Vue.set(state.items, index, undefined);
        }
      });
    },
  },
};
