<template lang="pug">
main(v-if='isReady')
  .page.page--account(:class='pageClass' style='padding-top: 60px')
    header-component(
      :active-organization='organizationSelected'
      :organizations='organizations'
      :user='$root.user'
      v-on:change-organization='changeOrganizaton'
    )
    .container-fluid
      .row
        #sidebar.col.max-width-sidebar-xl-new(
          v-if='showSideBar || $root.isMobile'
        )
          .sidebar-base(style='margin-left: -15px')
            simple-sidebar(:nav='nav')
              template(v-slot:sidebar-header)
                .simple-sidebar__header
                  .d-flex
                    .avatar-block
                      button.p-0(@click='$refs.inputFile.click()')
                        icon(name='pen-to-square')
                      img(:src='avatar' style='width: 56px')
                      input.d-none(
                        @change='imageFileChangeEvent($event)'
                        accept='image/*'
                        ref='inputFile'
                        type='file'
                      )
                    .ms-2
                      .text-name.text-overflow-ellipse.mt-1(
                        style='max-width: 130px'
                      ) {{ $root.user.name }}
                      .text-function {{ roleInOrganization }}
              template(v-slot:sidebar-middle)
              template(v-slot:sidebar-footer)
                .sidebar-footer
                  plan-info.plan-info
        .col
          .content
            h4.my-3.mt-4.page-title(v-if='pageHead') {{ pageHead }}
            router-view(:currentOrganization='organizationSelected')
          #backdrop(@click='closeSidebar()')
          footer-component(style='margin: 0 -12px')
    update-avatar-modal(
      :file='files'
      @refresh='avatar = $event'
      ref='updateAvatarModal'
    )
</template>
<script>
import UpdateAvatarModal from '@/modules/account/components/organisms/modals/UpdateAvatarModal.vue';
import HeaderComponent from '@/ui/organisms/Header.vue';
import FooterComponent from '@/ui/organisms/Footer.vue';
import SimpleSidebar from '@/ui/organisms/SimpleSidebar.vue';
import Icon from '@/ui/atoms/Icon.vue';
import DropdownSidebar from '@/ui/molecules/DropdownSidebar.vue';
import AppConfig from '@/config';
import PlanInfo from '@/ui/molecules/PlanInfo.vue';

export default {
  name: 'AccountLayout',
  components: {
    HeaderComponent,
    FooterComponent,
    SimpleSidebar,
    Icon,
    UpdateAvatarModal,
    DropdownSidebar,
    PlanInfo,
  },
  data() {
    return {
      user: '',
      sideBar: true,
      isReady: false,
      files: null,
      avatar: '',
    };
  },
  async created() {
    this.avatar = this.$root.user.avatar;
    await this.checkSelectedOrganization();
    await this.$store.dispatch('subscription/getSubscription');
    this.$loading(false);
    this.isReady = true;
  },
  computed: {
    pageClass() {
      return this.$store.state.page.class;
    },
    pageHead() {
      return this.$store.state.page.head;
    },
    showSideBar() {
      return this.$store.state.page.sideBar;
    },
    organizations() {
      return this.$store.state.organizations.items;
    },
    organizationSelected() {
      return this.$store.state.organizations.selected;
    },
    roleInOrganization() {
      if (!this.organizationSelected.is_owner) {
        return 'Usuário';
      }
      return 'Administrador';
    },
    linkTermsOfUse() {
      return AppConfig.authorizationServer + '/terms';
    },
    linkPrivacyPolicy() {
      return AppConfig.authorizationServer + '/privacy';
    },
    nav() {
      const commonItems = [
        {
          label: 'Início',
          icon: 'house',
          route: { name: 'business.dashboard' },
        },
        {
          label: 'Perfil',
          icon: 'circle-user',
          route: { name: 'account.profile' },
        },
        {
          label: 'Contatos',
          icon: 'address-book',
          route: { name: 'account.contacts' },
        },
      ];
      const adminItems = [
        {
          label: 'Meu plano',
          icon: 'dollar-sign',
          route: { name: 'account.billing.plan' },
        },
        {
          label: 'Usuários',
          icon: 'user',
          route: { name: 'account.users' },
        },
      ];

      const additionalItems = this.organizationSelected.is_owner
        ? adminItems
        : [];

      const items = commonItems.concat(additionalItems, [
        {
          label: 'Desenvolvedor',
          icon: 'code',
          route: { name: 'account.developer' },
        },
        {
          label: 'Configurações',
          icon: 'gear',
          route: { name: 'account.configuration' },
        },
      ]);

      return { items };
    },
  },
  methods: {
    closeSidebar() {
      this.$root.closeSidebar();
    },
    changeOrganizaton(organization) {
      this.$store.dispatch('organizations/setSelected', organization);
    },
    imageFileChangeEvent($event) {
      if ($event.target.files[0].type.indexOf('image/') === -1) {
        console.error('type file not accept');
        this.$refs.inputFile.value = null;
        return;
      }
      if ($event.target.files.length) {
        this.files = new Blob($event.target.files, { type: 'imag/png' });
        this.$refs.updateAvatarModal.$refs.modal.open();
        this.$refs.inputFile.value = null;
      }
    },
    async checkSelectedOrganization() {
      const organization = this.organizationSelected;
      await this.$store.dispatch('organizations/getItems');
      let selectedOrganization = null;
      if (organization) {
        // Check if selected organization is present on organizations list
        selectedOrganization = this.organizations.find(
          (item) => item.id === organization
        );
      }
      if (!selectedOrganization) {
        // Set first organization as a default organization
        selectedOrganization = this.organizations.find(
          (item) => item !== undefined
        );
      }
      await this.$store.dispatch(
        'organizations/setSelected',
        selectedOrganization
      );
    },
  },
};
</script>
<style lang="stylus">
.page--account
  &.page
    .content
      min-height: calc(100vh - 140px)
  .simple-sidebar__footer
    width: calc(100% - 3rem)
  .page-title
    border-bottom: 0 !important
  .simple-sidebar__header
    text-align: left
    padding-bottom: 0.38rem
    .avatar-block
      position: relative
      cursor: pointer
      img
        border-radius: 50%
      .icon
        display: flex
        align-items: center
        justify-content: center
        opacity: 0
        position: absolute
        color: #fff
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin: auto
        height: 56px
        width: 56px
        background-color: rgba(1,61,82, .7)
        padding: 1em;
        border-radius: 50%;
      &:hover
        .icon
          opacity: 1
    .text-function
      font-size: 0.813rem
      color: #555555
    .text-name
      font-size: 1rem
      color: #013D52
      font-weight: 600
    .organization-menu-block
      .simple-sidebar-organization
        span
          display: inline-block
          overflow: hidden
          white-space: nowrap
          text-overflow: ellipsis
          margin-right: 0.625rem
          margin-left: 0.625rem
          width: 7.188rem
        .icon
          font-size: 1rem
  .auxiliar-menu
    ul, li
      font-size: 1rem
      margin-top: 0.688rem
      a
        color: #555555
        text-decoration: none;
  .plan-info
    bottom: 30px
    width: calc(235px - 2.5rem)
  @media screen and (max-height: 745px)
    .simple-sidebar__footer
      position: static !important
    .simple-sidebar-new
      overflow-y: auto
      overflow-x: hidden
</style>
