<template lang="pug">
header.header.shadow
  .container-fluid.pe-xl-0
    .d-flex.align-items-center
      button#open-sidebar.open.d-xl-none
        icon.me-2(name='bars')
      router-link(:to='logoUrl')
        img.logo(:src='logo')
      .ms-auto.d-none.d-xl-block.header__plan-detail(
        v-if='aplication !== "public"'
      )
        //- span.text Créditos restantes:
        //- span.text.ms-1(style='color: #01afec') {{ remainingSignatures }}
      .ms-auto.ms-xl-2
        ul.list-unstyled.header-actions-list
          li.list-item(v-if='aplication !== "public"')
            .dropdown
              button#selectOrganization.dropdown-toggle(
                :disabled='organizations.length <= 1'
                data-bs-toggle='dropdown'
              )
                .select-organization-title
                  .header-organization
                    icon.me-2(name='sitemap')
                    span.text {{ selectedOrganization }}
                    template(v-if='organizations.length > 1')
                      icon(name='angle-down')
              .dropdown-menu(aria-labelledby='productsDropdown')
                services-select(
                  :items='organizations'
                  :user='user'
                  v-on:change='change'
                )
          li.list-item(v-if='aplication !== "public"')
            .dropdown
              button#avatarMenuDropdown.dropdown-toggle(
                data-bs-toggle='dropdown'
              )
                .header-avatar
                  img.img(:src='user.avatar')
              .dropdown-menu(aria-labelledby='avatarMenuDropdown')
                ul.header-avatar-menu-list.list-unstyled
                  template(v-if='aplication === "account"')
                    li.list-item
                      a.list-item-link(href='/business')
                        icon(:fix-width='true' name='table-cells')
                        span.text Tela Inicial
                  template(v-if='aplication === "business"')
                    li.list-item
                      a.list-item-link(href='/account')
                        icon(:fix-width='true' name='gear')
                        span.text Gerenciar Conta
                  li.list-item
                    a.list-item-link(@click='logout' style='cursor: pointer')
                      icon(:fix-width='true' name='right-from-bracket')
                      span.text Sair
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import UserService from '@/services/AccountHttp';
import ServicesSelect from '@/ui/molecules/ServicesSelect.vue';
import AppConfig from '@/config';

export default {
  name: 'HedaerOrganism',
  components: {
    Icon,
    ServicesSelect,
  },
  props: {
    user: Object,
    organizations: Array,
    activeOrganization: [Object, String, Boolean],
    application: String,
  },
  data() {
    return {
      aplication: this.$route.path.split('/')[1],
      style: this.$store.state.organizations.selected.style,
    };
  },
  computed: {
    notifications() {
      return [
        {
          name: 'James Dean',
          date: '31/02/2021',
          status: 0,
        },
        {
          name: 'John Wick',
          date: '02/07/2020',
          status: 1,
        },
      ];
    },
    logoUrl() {
      if (this.aplication === 'public') {
        return '/public';
      }
      return '/business/dashboard';
    },
    logo() {
      if (this.$store.state.organizations.selected.logo) {
        return this.$store.state.organizations.selected.logo;
      }
      return 'https://cdn.assine.online/assets/images/rebrand-intellisign/logo.svg';
    },
    selectedOrganization() {
      return this.activeOrganization.name
        ? this.activeOrganization.name
        : this.user.name;
    },
    remainingSignatures() {
      if (!this.$store.state.subscription.plan.quota) {
        return 0;
      }
      return (
        this.$store.state.subscription.plan.quota.envelopes.available -
        this.$store.state.subscription.plan.quota.envelopes.consumed
      );
    },
  },
  methods: {
    async logout() {
      const result = await UserService.logout();
      if (result.status === 204) {
        window.location.assign(AppConfig.loginPath);
      }
    },
    selectItem(event) {
      this.$emit('change-organization', event);
    },
    change(event) {
      this.$emit('change-organization', event);
    },
  },
};
</script>
<style lang="stylus">
.header
  line-height: 0.4em
  .header__plan-detail
    font-weight: 600
    color: #013D52
  .header-avatar-menu-list
    min-width: 189px
    .list-item-link
      padding: 0
      line-height: 2.3em
  #selectOrganization[disabled]
    .header-organization
      cursor: initial
  .dropdown-menu
    overflow: hidden
    .list-item
      margin-bottom: 0rem;
      &:first-child
        margin-top: 0rem;
      &:last-child
        margin-bottom: 0rem;
      &:hover
        background-color: #f0f0f0
@media (min-width: 1200px)
  .header-organization
    border-right: 1px solid #013D52
    padding-right: 1.2rem
    margin-right: .2rem
    .text
      color: #013D52
      font-weight: 600
</style>
