<template lang="pug">
.row
  .col-12.col-sm-6.col-md-4
    .row
      .col-12
        .form-group
          label(for='role') Empresas
          select.form-select.form-select-lg.mb-8(
            @change='selectOrganization'
            v-model='selectedOrganization'
          )
            option(value='') Selecione uma Opção
            option(
              :value='organization.id'
              v-for='(organization, index) in organizations'
            ) {{ organization.name }}
    .card.mb-4(v-if='selectedOrganization')
      .card-body
        .row
          .col-12
            img.img.mb-3(
              src='https://cdn.assine.online/assets/images/rebrand-intellisign/logo.svg'
              style='max-height: 60px'
            )
          .col-12
            h6(style='color: #013d52; font-weight: 600') {{ subscriptionName }}
            small {{ subscription.properties.card_banner || 'visa' }} ••••
              | {{ subscription.properties.card_last_four_digits || '0000' }}
            small(style='position: absolute; right: 1rem')
              | Renova em {{ subscription.next_recurrence_at | dateTime }}
            p.mt-3.mb-0.text-end
              router-link(:to='`/account/billing/plan/${subscription.id}`') Gerenciar
  //- .row.mt-5
    .col-12
      h3.sub-title Compras de créditos
    .col-12.col-sm-6.col-md-4
      .card
        .card-body
          .row
            .col-4
              i.icon.fas.fa-fw.fa-coins(style='font-size: 2.5rem; color: #013D52;')
            .col-8(style='font-size: 0.9rem;')
              p.mb-0 R$ 123,76
              p.mb-0 Expira em 09/22
    .col-12.col-sm-6.col-md-4
      a.card(href='#' data-bs-toggle='modal' data-bs-target='#add-credit'
        style='border: 1px dashed #01AFEC;')
        .card-body
          p.mb-0.text-center(
            style='line-height: 2.6rem; color: #013d52; font-weight: 600;') Adicionar créditos
</template>

<script>
import BillingHttp from '@/services/BillingHttp';
import OrganizationService from '@/modules/account/services/http/OrganizationService';
import LocalStorage from '@/services/LocalStorage';

export default {
  name: 'BillingPage',
  data() {
    return {
      subscription: {},
      organizations: [],
      selectedOrganization: '',
    };
  },
  computed: {
    subscriptionName() {
      return this.subscription.plan ? this.subscription.plan.name : '';
    },
  },
  async created() {
    this.$store.dispatch('page/setTitle', 'Plano');
    this.$store.dispatch('page/setClass', 'acount plans');
    this.$store.dispatch('page/setHead', { title: 'Plano' });
    await this.fetchOrganizations();
    await this.getSelectedOrganization();
    this.getSubscription();
  },
  methods: {
    async fetchOrganizations() {
      await OrganizationService.list().then((response) => {
        const { data } = response.data;
        data.forEach((organization) => {
          if (
            organization.is_owner ||
            organization.permissions.manage_billing
          ) {
            this.organizations.push(organization);
          }
        });
      });
    },
    getSelectedOrganization() {
      const organizaçãoPreSelecionada = this.organizations.find(
        (organization) => organization.id === LocalStorage.get('organization')
      );
      if (organizaçãoPreSelecionada || this.selectedOrganization === '') {
        this.selectedOrganization = organizaçãoPreSelecionada.id;
      }
    },
    selectOrganization(e) {
      LocalStorage.set('organization', e.target.value);
      this.selectedOrganization = e.target.value;
      this.getSubscription();
    },
    async getSubscription() {
      await BillingHttp.getSubscription().then((ret) => {
        this.subscription = ret.data;
      });
    },
  },
};
</script>
