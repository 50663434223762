import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=22ce9d79&scoped=true&lang=pug"
import script from "./Loading.vue?vue&type=script&lang=js"
export * from "./Loading.vue?vue&type=script&lang=js"
import style0 from "./Loading.vue?vue&type=style&index=0&id=22ce9d79&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ce9d79",
  null
  
)

export default component.exports