<template lang="pug">
modal.invite-user-modal(
  :no-footer='true'
  :title='title'
  @closed='onCloseModal'
  @opened='onOpenModal'
  auxButtonLabel='Volteeaqui'
  closeButtonLabel='Voltar'
  processButtonLabel='Avançar'
  ref='modal'
  size='xl'
)
  template(v-if='noSelection')
    .title-send-invite.text-center
      strong Como deseja convidar seus usuários?
    .row.mt-3
      .col-12.col-lg-6.mb-4.px-3
        button.btn.btn--email-manually.text-center.p-5(@click='selectManually')
          .d-block.mb-2
            img(
              alt='adicionar email'
              src='/img/icons/icon-invite-user-manually.svg'
            )
          span.text Inserir manualmente
      .col-12.col-lg-6.px-3
        button.btn.btn--email-file.text-center.p-5(@click='selectFileEmail')
          .d-block.mb-2
            img(
              alt='adicionar email'
              src='/img/icons/icon-invite-user-with-file.svg'
            )
          span.text Enviar arquivo
  .d-flex.flex-column(v-else-if='isManually')
    div
      .title-manually-email.text-center
        | Insira um ou mais e-mails para participar de sua conta Intellisign
      .input--write-emails.w-100.justify-content-center.d-flex.mb-4
        .form-control-icon.w-100(
          :class='{ hideIcon: hasOptions }'
          ref='options'
        )
          icon(name='at' style='height: auto; top: 1.1em')
          VueTagsInput.input.tags-input.form-control(
            :placeholder='placeholder'
            :tags='tags'
            @tags-changed='(newTags) => (tags = newTags)'
            autocomplete='off'
            v-model='tag'
          )
          #custom-feedback-validation(v-if='hasOptions')
            .custom-feedback.length.mt-1.d-flex
              icon#icon-circle.me-2(name='circle')
              .text Use a tecla "Enter" para separar as opções
    .footer-buttons
      button.btn.btn-secondary(@click='emailChoice = null') Voltar
      button.btn.btn-primary.ms-4(
        :disabled='!this.tags.length > 0'
        @click='inviteUserSendEmail'
      )
        span.text {{ textButtonNextStep }}
  div(v-else-if='isFileEmail')
    .text-center
      p.fs-3.title-upload-invite-file Envie seu arquivo contendo todos os e-mails
    input(
      :accept='allowedFileType.join()'
      @change='loadFile'
      multiple
      ref='inputFile'
      style='display: none'
      type='file'
    )
    .loaded-files-list.d-flex.justify-content-center(v-if='hasLoadedFiles')
      template(v-for='(item, index) in computerFiles')
        .loaded-files-list__item(:key='item.index')
          .content.d-flex.align-items-center.p-3
            .name {{ item.file.name }}
            .ms-auto
              button.p-0(@click='removeFileItem(item)' title='Remover')
                icon.icon-action(
                  name='circle-xmark'
                  v-if='loadFileErrorMessage'
                )
                icon.icon-action(name='trash-can' v-else)
          .file-error-message(v-if='loadFileErrorMessage') {{ loadFileErrorMessage }}
    .card--upload-file.text-center(v-else)
      button.btn.btn-thin(@click='selectFile') Selecionar arquivo
      h3.text-to-inform-user.fs-5.mt-2.pt-3 Insira um arquivo .xlsx, .xls, .csv, .ods ou .txt
      p.text-center Os e-mails devem estar na primeira coluna. <br> Deve-se deixar um e-mail por linha.
        | <br>Envie arquivo de no máximo 5mb
    .footer-buttons
      button.btn.btn-secondary(@click='emailChoice = null') Voltar
      button.btn.btn-primary.ms-4(
        :disabled='this.computerFiles.length === 0 || hasInvalidFile'
      )
        span.text(@click='inviteUserSendEmailFromFile') {{ textButtonNextStep }}
  div(v-else-if='isSending')
    .col-12
      table-component(:columSize='["100%"]')
        template
          tr(:key='invite.email' v-for='invite in sendedInvites')
            td.feedback-invited-emails.pb-1.d-flex
              span.text.message-color-email-feedback {{ invite.email }}
              span.icon-ignored(v-if='invite.type === "ignored"')
                icon.icon-ignored.ms-2.me-2(name='stopwatch')
                span.me-1 Já pertence à organização ou existe um convite pendente
              span.icon-invited(v-else-if='invite.type === "invited"')
                icon.icon-invited.ms-2.me-2(name='circle-check')
                span.me-1 Enviado
              span.icon-invalid(v-else-if='invite.type === "invalid"')
                icon.icon-invalid.ms-2.me-2(name='circle-exclamation')
                span.me-1 Formato inválido
    .footer-buttons
      button.btn.btn-primary.ms-4(:disabled='disableButtonNextStep')
        span.text(@click='closeInviteUserModal') Fechar
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import UsersService from '@/services/UsersService';
import InvitationService from '@/services/InvitationService';
import alert from '@/modules/account/common/alert';
import TableComponent from '@/ui/organisms/Table.vue';
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  name: 'InviteUserModal',
  components: {
    Modal,
    IconStatus,
    ImageLoader,
    UsersService,
    Icon,
    InvitationService,
    TableComponent,
    VueTagsInput,
  },
  data() {
    return {
      customMessagesEmail: {
        required: 'É necessário inserir um e-mail',
        email: 'É necessário inserir um e-mail válido',
      },
      computerFiles: [],
      emailChoice: null,
      errors: {},
      emails: [],
      sendedInvites: [],
      tag: '',
      tags: [],
      title: 'Convidar usuário(s)',
      loadFileErrorMessage: '',
      status: 'uploading',
      allowedFileType: [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'text/csv',
        'application/vnd.oasis.opendocument.spreadsheet',
        'text/plain',
      ],
      maxFileSizeMb: 5,
    };
  },
  props: {
    textButtonNextStep: {
      type: String,
      default: 'Enviar convites',
    },
    disableButtonNextStep: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUploading() {
      return this.status === 'uploading';
    },
    getClass() {
      if (this.isUploading) {
        return 'is-uploading';
      }
      if (this.isError) {
        return 'is-error';
      }
      if (this.isUploaded) {
        return 'is-uploaded';
      }
      return '';
    },
    isManually() {
      return this.emailChoice === 'M';
    },
    isFileEmail() {
      return this.emailChoice === 'F';
    },
    isSending() {
      return this.emailChoice === 'X';
    },
    noSelection() {
      return this.emailChoice === null;
    },
    hasOptions() {
      return this.tags.length > 0;
    },
    isError() {
      return this.status === 'error';
    },
    placeholder() {
      return this.hasOptions
        ? ''
        : 'Use a tecla "Enter" para separar as opções';
    },
    hasLoadedFiles() {
      return this.computerFiles.length > 0;
    },
    hasSelectedFiles() {
      return this.hasLoadedFiles;
    },
    hasInvalidFile() {
      return this.computerFiles.filter((i) => i.isInvalid).shift() || false;
    },
  },
  methods: {
    selectManually() {
      this.emailChoice = 'M';
    },
    selectFileEmail() {
      this.emailChoice = 'F';
    },
    removeEmail() {
      this.tags.splice(this.tags);
    },
    updateEmails(newEmail) {
      this.tags.push(newEmail[newEmail.length - 1].text);
    },
    invitesSending() {
      this.setModalTitle('Convites enviados com sucesso!');
      this.emailChoice = 'X';
    },
    clickedOnSendFileEmail() {
      this.sendFileEmail = !this.sendFileEmail;
    },
    removeFileItem(item) {
      this.computerFiles = this.computerFiles.filter(
        (i) => i.index !== item.index
      );
    },
    mapSendedInvites(responseArray) {
      const newArray = [];

      responseArray.ignored.map((email) => {
        newArray.push({ email, type: 'ignored' });
      });
      responseArray.invited.map((email) => {
        newArray.push({ email, type: 'invited' });
      });
      responseArray.invalid.map((email) => {
        newArray.push({ email, type: 'invalid' });
      });

      return newArray;
    },
    closeInviteUserModal() {
      this.$refs.modal.close();
    },
    selectFile() {
      this.$refs.inputFile.click();
    },
    removeFileNotUploaded() {
      this.$emit('removeFile', this.payload);
    },
    loadFile(e) {
      this.loadFileErrorMessage = '';
      e.target.files.forEach((file) => {
        let isInvalid = false;
        const res = this.isInvalidFile(
          file,
          this.maxFileSizeMb,
          this.allowedFileType
        );
        if (res) {
          this.loadFileErrorMessage = res;
          isInvalid = true;
        }
        this.computerFiles.push({
          index: new Date().getTime() + Math.random(),
          file,
          isInvalid,
        });
      });
    },
    inviteUserSendEmail() {
      this.$loading(true);
      this.invitesSending();
      const payload = {
        emails: this.tags.map((v) => {
          return v.text;
        }),
      };
      InvitationService.invite(payload)
        .then((res) => {
          this.sendedInvites = this.mapSendedInvites(res.data);
          this.tags = [];
          this.tag = '';
          this.$emit('invites-sent');
        })
        .catch((error) => {
          alert.fireAlert(
            `Não foi possível convidar usuário(s))! ${error.response.data.message}`,
            {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
              icon: 'triangle-exclamation',
            }
          );
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    inviteUserSendEmailFromFile() {
      this.$loading(true);
      this.invitesSending();
      InvitationService.inviteFromFile(this.computerFiles[0].file)
        .then((res) => {
          this.sendedInvites = this.mapSendedInvites(res.data);
          this.computerFiles = [];
          this.$emit('invites-sent');
        })
        .catch((error) => {
          alert.fireAlert(
            `Não foi possível convidar usuário(s))! ${error.response.data.message}`,
            {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
              icon: 'triangle-exclamation',
            }
          );
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    setModalTitle(title) {
      this.title = title;
    },
    isInvalidFile(file, maxFileSizeMb, allowedFileType) {
      const maxFileSizeBytes = maxFileSizeMb * 1024 * 1024;
      if (!allowedFileType.includes(file.type)) {
        return `Formato inválido. Tipo de arquivo aceito: .xlsx, .xls, .csv, .ods ou .txt.`;
      }
      if (file.size > maxFileSizeBytes) {
        return `Tamanho máximo excedido. Insira arquivos com até ${maxFileSizeMb}MB.`;
      }
    },
    onOpenModal() {
      this.emailChoice = null;
      this.computerFiles = [];
      this.tags = [];
      this.tag = '';
    },
    onCloseModal() {
      this.onOpenModal();
    },
  },
};
</script>

<style lang="stylus">
.invite-user-modal
  .inserted-email
    background-color: #E5EBED
    border: 2px solid #C3D1D6
    color: #013D52
  .title-send-invite
    font-size: 1.375rem
    color: #013D52
  .title-upload-invite-file
    font-size: 1.375rem
    padding: 20px
    color: #013D52
    margin-bottom: 0
  .title-manually-email
    font-size: 1.375rem
    padding: 20px
    color: #013D52
    font-weight: 600
  .input--write-emails
    margin-left: auto
    margin-right: auto
  .input-emails
    width: 60%
    align-self: center
  .btn--email-manually, .btn--email-file
    width: 80%
    margin: auto
    display: block
    font-weight: 400
  .btn--email-manually
    color: #013D52;
    background-color: #F7F7F7
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
    border-radius: 5px
  .btn--email-file
    color: #013D52;
    background-color: #F7F7F7
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
    border-radius: 5px
  .form-control-icon
    width: 50%
  .footer-buttons
    display: flex
    justify-content: flex-end
  #icon-circle
    font-size: 0.5rem
    align-self: center
  .tags-input
    height: auto
  .custom-feedback
    font-size: 0.875rem
    padding-left: calc(1.5em + 0.75rem)
    background-repeat: no-repeat
    background-position: left calc(0.375em + 0.1875rem) center
    background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem)
    color: #949494
  .vue-tags-input
    max-width: 100%
  .icon-invalid
    color: #D00A00
  .icon-invited
    color: #3ECB90
  .icon-ignored
    color: #F97E03
  .message-color-email-feedback
    color: #013D52
  .text-to-inform-user
    color: #555555;
  .loaded-files-list__item
      border: 1px solid #ffeae9
      border-radius: 3px
      margin: 10px 0
      width: 100%
      max-width: 520px
      &:hover
        box-shadow: 0px 3px 5px rgba(0, 0, 0, .07)
      .content
        .name
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          font-size: .875rem
        .icon-action
          font-size: 1.2em
      .file-error-message
        background-color: #ffeae9
        padding: 0.2rem 1rem
        font-size: 0.7em
        color: #d00a00
  .card--upload-file
    .dragenterClass
      cursor: grabbing
      cursor: -webkit-grabbing
      background:rgba(0,0,0,0.1)
      .notclickable
        pointer-events: none;
        h3
          pointer-events: none;
        p
          pointer-events: none;
@media screen and (max-width: 991px)
  .invite-user-modal
    .feedback-invited-emails
      flex-direction: column
@media screen and (min-width: 992px)
  .invite-user-modal
    .btn--email-manually, .btn--email-file
      width: 80%
      margin: inherit
      max-width: 320px
    .btn--email-manually
      margin-left: auto
    .btn--email-file
      margin-right: auto
    .input--write-emails
      max-width: 50%
</style>
