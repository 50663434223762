<template lang="pug">
header
  .container-fluid
    .d-flex.align-items-center
      img.logo(
        :src='logoUrl'
        style='margin-left: auto; margin-right: auto; margin-top: 74px; margin-bottom: 30px'
      )
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import AppConfig from '@/config';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      aplication: this.$route.path.split('/')[1],
    };
  },
  computed: {
    logoUrl() {
      return `${AppConfig.cdnPath}/assets/images/rebrand-intellisign/logo-white.svg`;
    },
  },
};
</script>
