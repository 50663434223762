<template lang="pug">
modal#delete-developer-client-modal(
  :noFooter='true'
  @process='closeModal'
  ref='modal'
  title='Você já possui um pagamento em andamento!'
)
  .text-center
    img.mt-4.mb-3(
      alt='developer-delete-warning'
      src='/img/ilustrations/subscription-reminder.svg'
    )
    p.text-start Identificamos que você possui uma transação aguardando pagamento.
      |
      | Aguarde a transação ser concluída para alterar o plano.

  .modal-footer.p-0.d-flex.justify-content-end
    button#buttonColor.btn.btn-primary(@click='closeModal' type='button')
      | Fechar
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'CreatingAppInfoModal',
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.$refs.modal.close();
    },
  },
};
</script>
