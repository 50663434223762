<template lang="pug">
.plan-comparison
  .row(style='margin-top: 32px; margin-bottom: 32px')
    .col-12
      .card.card--plan-comparison
        a(
          @c
          aria-controls='planComparison'
          aria-expanded='false'
          data-bs-toggle='collapse'
          href='#planComparison'
          role='button'
        )
          .card-body
            span.text Tabela comparativa de planos
            icon.icon-arrow(name='chevron-down')
        #planComparison.collapse
          .col-12.col-table
            table.table.table-bordered
              tbody
                tr
                  th(style='vertical-align: bottom; padding-bottom: 1rem')
                    .text(style='font-weight: 600; font-size: 1rem') Funcionalidades
                  th(:key='index' v-for='(plan, index) in plans')
                    .header-plan
                      .header-plan-title
                        icon(:name='plan.icon')
                        span.text {{ plan.title }}
                      .header-plan-description
                        .text {{ plan.description }}
                      .header-plan-button
                        button.btn(:class='plan.buttonClass' type='button') {{ plan.buttonText }}
                tr(
                  :class='feature.rowClass || ""'
                  :key='index'
                  v-for='(feature, index) in features'
                )
                  th
                    .text {{ feature.name }}
                  th(
                    :key='i'
                    v-for='(value, i) in feature.values'
                    v-if='!feature.rowClass'
                  )
                    template(v-if='value === "check"')
                      img(src='/img/icons/circle-check.svg')
                    template(v-else)
                      .text {{ value }}
                  th(colspan='4' v-if='feature.rowClass')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'PlanComparison',
  components: { Icon },
  data() {
    return {
      plans: [
        {
          title: 'Básico',
          description: `Perfeito para iniciantes ou autônomos, oferecendo uma solução prática e econômica
             para enviar e assinar documentos`,
          icon: 'leaf',
          buttonClass: 'btn-thin',
          buttonText: 'Comece agora',
        },
        {
          title: 'Essencial',
          description: `Para pequenos negócios em crescimento, com mais funcionalidades para
             gerenciar assinaturas digitais de forma eficiente`,
          icon: 'briefcase',
          buttonClass: 'btn-thin',
          buttonText: 'Comece agora',
        },
        {
          title: 'Avançado',
          description: `Ideal para equipes de até 15 pessoas, com ferramentas avançadas
             para agilizar e gerenciar assinaturas digitais`,
          icon: 'building',
          buttonClass: 'btn-primary',
          buttonText: 'Comece agora',
        },
        {
          title: 'Corporativo',
          description: `Solução completa para grandes empresas, com máxima flexibilidade
             e recursos para gerenciar grandes volumes de assinaturas`,
          icon: 'rocket',
          buttonClass: 'btn-corporative',
          buttonText: 'Falar com consultor',
        },
      ],
      features: [
        {
          name: 'Utilização',
          rowClass: 'linha-cinza',
        },
        {
          name: 'Quantidade de usuários',
          values: ['até 5', 'até 10', 'até 20', 'sob medida'],
        },
        {
          name: 'Quantidade de assinantes',
          values: ['ilimitados', 'ilimitados', 'ilimitados', 'ilimitados'],
        },
        {
          name: 'Divisão por documentos/envelopes',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Armazenamento',
          rowClass: 'linha-cinza',
        },
        {
          name: 'Espaço para armazenar documentos',
          values: ['500 MB', '1 Gb', '3 Gb', 'sob medida'],
        },
        {
          name: 'Armazenamento de documentos originais',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Criar pastas',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Criar subpastas',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Envios',
          rowClass: 'linha-cinza',
        },
        {
          name: 'Quantidade de envios',
          values: ['180 por ano', '360 por ano', '600 por ano', 'sob medida'],
        },
        {
          name: 'Notificação por WhatsApp',
          values: ['-', 'check', 'check', 'check'],
        },
        {
          name: 'Envio por Email',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Envio sem ordenar os assinantes',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Relatório de envios',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Formas de assinar',
          rowClass: 'linha-cinza',
        },
        {
          name: 'Assinatura eletrônica',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Assinatura avançada com OTP  (Certificado digital em nuvem Bird ID)',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Assinatura com certificado digital (A1 e A3)',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Assinatura com carimbo do tempo',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Assinatura com foto',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Assinatura em lote',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Assinatura presencial',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Combinação de assinaturas simples e qualificadas no mesmo envelope',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Rubrica',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Gestão de documentos',
          rowClass: 'linha-cinza',
        },
        {
          name: 'Compartilhamento de documentos e fluxos de trabalho',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Mover documento de uma pasta para outra',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Upload em massa de documentos',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Compartilhar, renomear e excluir pastas',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Permitir salvar template de documentos',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Compatibilidade',
          rowClass: 'linha-cinza',
        },
        {
          name: 'OS compatível com Windows, OS Linux compatível, MacOS compatíveis',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Assinatura via telefone celular ou tablet',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Comp. Chrome, Safari, Firefox, Opera',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Personalização',
          rowClass: 'linha-cinza',
        },
        {
          name: 'Personalização por logotipo',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Personalização por envelope',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Segurança',
          rowClass: 'linha-cinza',
        },
        {
          name: 'Relatórios de auditoria e rastreabilidade',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Utilização de SSL na comunicação de dados',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Política de armazenamento',
          values: ['check', 'check', 'check', 'check'],
        },
        {
          name: 'Integrações',
          rowClass: 'linha-cinza',
        },
        {
          name: 'Integração via API',
          values: ['-', '-', 'check', 'check'],
        },
      ],
    };
  },
  mounted() {
    const collapseElement = this.$el.querySelector('#planComparison');
    collapseElement.addEventListener('shown.bs.collapse', () => {
      this.equalizeHeaderPlans();
      window.addEventListener('resize', this.equalizeHeaderPlans);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.equalizeHeaderPlans);
  },
  methods: {
    equalizeHeaderPlans() {
      const headerPlans = this.$el.querySelectorAll('.header-plan-description');
      let maxHeight = 0;
      headerPlans.forEach((header) => (header.style.height = ''));

      headerPlans.forEach((header) => {
        const height = header.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      headerPlans.forEach((header) => {
        header.style.height = `${maxHeight}px`;
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
tbody
  tr
    border-left: none !important
    border-right: none !important
.btn-corporative
  background-color: #202020
  color: #FFFFFF
</style>
