<template lang="pug">
modal#register-cpf-modal(
  :noFooter='true'
  @process='closeModal'
  ref='modal'
  title='CPF não cadastrado'
)
  .text-center
    p.text-start Não existe CPF vinculado à sua conta.,
    p.text-start Por favor, cadastre seu CPF para continuar a contratação do plano.

  ValidationObserver(ref='form' v-slot='{ valid }')
    .col-12.col-md-12.form-group
      ValidationProvider(
        name='cpf'
        rules='required|cpf'
        v-slot='{ errors }'
      )
        label(for='cpf') CPF:
        .form-control-icon
          icon(name='id-card')
          input#cpf.form-control(
            autocomplete='off'
            placeholder='Digite seu CPF'
            type='cpf'
            v-mask='["###.###.###-##"]'
            v-model='user.cpf'
          )

        small.align-items-center.text.text-danger.d-flex.mt-1(
          v-if='errors.length > 0'
        )
          icon(name='xmark')
          span.text.ms-2 {{ errors[0] }}

    .modal-footer.p-0.d-flex.justify-content-end
      button#buttonColor.btn.btn-primary(
        :disabled='!valid'
        @click='submit'
        type='button'
      )
        | Cadastrar CPF
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import AccountService from '@/services/AccountHttp';
import processData from '@/utils/processData';
import Icon from '@/ui/atoms/Icon.vue';
import alert from '@/modules/account/common/alert';

export default {
  name: 'CreatingAppInfoModal',
  data() {
    return {
      user: {
        cpf: null,
      },
    };
  },
  components: {
    Modal,
    Icon,
  },
  methods: {
    closeModal() {
      this.$refs.modal.close();
    },
    async submit() {
      this.$loading(true);
      const userData = {
        name: this.$root.user.name,
        phone: this.$root.user.phone,
        cpf: processData.extractOnlyNumbers(this.user.cpf),
      };
      await AccountService.setUser(userData)
        .then(() => {
          alert.fireAlert('CPF cadastrad com sucesso', {
            classes: 'alert-success',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
            icon: 'circle-check',
          });
          this.closeModal();
          this.$emit('signPlan');
        })
        .catch(() => {
          alert.fireAlert('Erro ao tentar processar atualização', {
            classes: 'alert-danger',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
            icon: 'triangle-exclamation',
          });
          this.$loading(false);
        });
    },
  },
};
</script>
