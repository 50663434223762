<template lang="pug">
div
  .row(style='margin-top: 32px; margin-bottom: 32px')
    .col-12
      .switch
        label.switch-light
          input(
            @click='$emit("togglePlan")'
            checked
            type='checkbox'
          )
          span 
            span.text.mensal Mensal
            span.text.anual Anual
          a.btn.btn-switch-selected
          .discount economize até 25%
  .row
    .col-12.col-sm-6.col-lg-3(
      :key='plan.id'
      v-for='plan in plans'
      v-if='showAnnuallyOrMonthly(plan)'
    )
      .card.card--plans(:class='getCardPlanStyle(plan.title.name)')
        .card-header 
          .card-tag.d-flex.flex-row-reverse
            .tag(
              :style='plan.title.name === "Essencial" ? "background-color: #FFB731" : ""'
            )
              icon(:name='plan.tag.icon')
              span.text {{ plan.tag.name }}
            .tag(
              style='margin-right: 0.313rem'
              v-if='isTheActivePlan(plan.id)'
            )
              icon(name='circle-check')
              span.text ATIVO
          .card-title
            icon(:name='plan.title.icon')
            span.text {{ plan.title.name }}
          .card-prices
            div(v-if='plan.price && lackPlan === "12"')
              .price-year
                span.money-cipher R$
                span.money-number {{ plan.price.annual.annual.number }}
                span.money-cents {{ plan.price.annual.annual.cents }}
              .price-month
                span.money-cipher R$
                span.money-number {{ plan.price.annual.monthly.number }}
                span.money-cents {{ plan.price.annual.monthly.cents }}
            div(v-else-if='plan.price && lackPlan === "1"')
              .price-year
                span.money-cipher R$
                span.money-number {{ plan.price.monthly.number }}
                span.money-cents {{ plan.price.monthly.cents }}
            div(v-else)
              .text(
                style='font-weight: 600; font-size: 24px; margin-top: 1.5rem; color: #202020'
              ) Sob medida
          hr
          .card-description
            .text {{ plan.description }}
        .card-body
          ul.list-unstyled 
            li(:key='benefit' v-for='benefit in plan.benefits')
              icon(name='circle-check')
              span.text {{ benefit }}
        .card-footer(v-if='!isTheActivePlan(plan.id)')
          button.btn.btn-primary(@click='submit(plan.id)' type='button') {{ getNameButton(plan.title.name) }}
  PlanComparison
  register-cpf-modal(@signPlan='signPlan' ref='registerCpfModal')
  payment-in-progressModal(ref='paymentInProgressModal')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import BillingHttp from '@/services/BillingHttp';
import PlanComparison from './PlanComparison.vue';
import RegisterCpfModal from '@/modules/account/components/organisms/modals/RegisterCpfModal.vue';
import PaymentInProgressModal from '@/modules/account/components/organisms/modals/PaymentInProgressModal.vue';

export default {
  name: 'MyPlans',
  components: {
    Icon,
    PlanComparison,
    RegisterCpfModal,
    PaymentInProgressModal,
  },
  data() {
    return {
      selectedPlan: null,
    };
  },
  props: {
    plans: Array,
    lackPlan: String,
  },
  methods: {
    getNameButton(planName) {
      return planName === 'Corporativo'
        ? 'Falar com um consultor'
        : 'Escolher plano';
    },
    getCardPlanStyle(planName) {
      const styles = {
        Avançado: 'card--plans--complete',
        Corporativo: 'card--plans--corporate',
        Essencial: 'card--plans--essential',
      };
      return styles[planName] || '';
    },
    showAnnuallyOrMonthly(plan) {
      if (plan.interval == this.lackPlan || plan.title.name == 'Corporativo') {
        return true;
      }
      return false;
    },
    isTheActivePlan(id) {
      if (
        id === this.$store.state.subscription.plan.plan.id &&
        this.$store.state.subscription.plan.state !== 'cancelled'
      ) {
        return true;
      }
      return false;
    },
    submit(id) {
      this.selectedPlan = id;
      if (!this.$root.user.cpf) {
        this.$refs.registerCpfModal.$refs.modal.open();
      } else {
        this.signPlan();
      }
    },
    async signPlan() {
      this.$loading(true);
      try {
        const invoice = await BillingHttp.signPlan({ plan: this.selectedPlan });
        const responseUrl = new URL(this.$route.path, window.location.origin)
          .href;
        window.location.href = `${invoice.data.checkout_url}&callBackResponseUrl=${responseUrl}?token=`;
      } catch (error) {
        this.$loading(false);
        if (error.response.status === 412) {
          this.$refs.paymentInProgressModal.$refs.modal.open();
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.discount
  font-size: 0.75rem
</style>
