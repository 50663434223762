import { render, staticRenderFns } from "./TopPageBar.vue?vue&type=template&id=28ed34d8&scoped=true&lang=pug"
import script from "./TopPageBar.vue?vue&type=script&lang=js"
export * from "./TopPageBar.vue?vue&type=script&lang=js"
import style0 from "./TopPageBar.vue?vue&type=style&index=0&id=28ed34d8&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ed34d8",
  null
  
)

export default component.exports