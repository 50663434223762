<template lang="pug">
.files-list-component.row
  .col-12
    .top-bar.d-flex.mb-3
      #col-1.search-n-filter.d-flex(
        v-if='!(isEmptyItems && !isSearching && !parent)'
      )
        .search-block.flex-grow-1
          .form-control-icon.form-control-icon--right(style='min-width: 280px')
            button.icon(style='color: #013d52')
              icon(name='magnifying-glass')
            input.form-control.ps-3(
              @keyup='searchItems()'
              autocomplete='off'
              placeholder='Buscar pasta ou documento'
              type='text'
              v-model.trim='queryItem'
            )

        .items-visualization-control.align-self-center.ms-2
          button(@click='changeViewMode("grid")')
            icon.fs-3.font-color-blue(
              name='border-all'
              v-if='ViewMode === "grid"'
            )
            icon.fs-3.font-color-blue(name='grid-2' v-else)
          button(@click='changeViewMode("list")')
            icon.fs-3.font-color-blue(
              name='table-list'
              v-if='ViewMode === "list"'
            )
            icon.fs-3.font-color-blue(name='list' v-else)

      #col-2.btn-space.d-flex.ms-auto
        .storage-limit
          .bar
            icon.icon--blue(name='hard-drive')
            .progress
              .progress-bar.bg-warning(
                :aria-valuenow='100'
                :style='`width: 2`'
                aria-label='Warning example'
                aria-valuemax='100'
                aria-valuemin='0'
                role='progressbar'
              )
          span.text {{ totalOrganizationSpentStorage }} de {{ totalOrganizationStorageLimit }} usados
        .dropdown-stylized.dropdown-stylized--with-border(
          v-if='(!isEmptyItems || !$root.isMobile) && canUploadFile'
        )
          button.w-100.d-flex(
            aria-expanded='false'
            data-bs-toggle='dropdown'
            type='button'
          )
            span.text.flex-grow-1 Novo
            icon.arrow(name='angle-down')
          ul.dropdown-menu(aria-labelledby='file-list-actions-dropdown')
            li
              button.dropdown-item(
                :disabled='canNotBeCreateFolders'
                data-bs-target='#new-storage-folder-modal'
                data-bs-toggle='modal'
                type='button'
              )
                icon(name='folder')
                span.text Pasta
            li
              button.dropdown-item(
                data-bs-target='#file-loader-modal'
                data-bs-toggle='modal'
                type='button'
              )
                icon(name='file-arrow-up')
                span.text Subir arquivo
    .col-12
      .col-12(v-if='breadCrumbs.current')
        nav(aria-label='breadcrumb' style='--bs-breadcrumb-divider: ">"')
          .breadcrumb
            li.breadcrumb-item
              router-link.h-title(:to='{ name: "business.storage" }') Armazenamento
            li.breadcrumb-item(v-if='breadCrumbs.parent')
              router-link.h-title(
                :to='{ name: "business.storage.item", params: { itemId: breadCrumbs.parent.id } }'
              )
                | {{ breadCrumbs.parent.name }}
            li.breadcrumb-item
              router-link.h-title.fw-bold(
                :to='{ name: "business.storage.item", params: { itemId: breadCrumbs.current.id } }'
              )
                | {{ breadCrumbs.current.name }}

      .files-list.files-list--cards(v-if='this.ViewMode === "grid"')
        .row.row-cols-2.row-cols-sm-2.row-cols-md-4.row-cols-lg-5.px-1
          .files-list__folders__item.col(
            :key='item.id'
            v-for='(item, index) in rootItemsCleaner'
          )
            .card.card--folder
              .card-body
                .row.d-flex.align-items-center
                  .name-items.col.col--name(
                    :title='item.name'
                    @click='openItem(item)'
                    style='cursor: pointer'
                  )
                    icon.me-3.fs-5(
                      :class='[itemIcon(item)]'
                      :name='itemIcon(item)'
                    )
                    span.text.fs-6 {{ fileName(item) }}
                  .col.col--date
                    span.text {{ item.created_at }}
                  .col.col--actions.text-end
                    .dropdown
                      button#file-list-actions-dropdown.btn-actions.dropdown-toggle(
                        aria-expanded='false'
                        data-bs-toggle='dropdown'
                        type='button'
                      )
                        icon.fs-5(name='ellipsis-vertical')
                      ul.dropdown-menu(
                        aria-labelledby='file-list-actions-dropdown'
                      )
                        li
                          button.dropdown-item(
                            @click='renameItem(item)'
                            type='button'
                          )
                            span.text Renomear
                        li
                          button.dropdown-item(
                            @click='moveTo(item)'
                            type='button'
                          )
                            span.text Mover para
                        li
                          button.dropdown-item(
                            @click='download(item)'
                            type='button'
                          )
                            span.text Baixar
                        li
                          button.dropdown-item(
                            @click='deleteItem(item)'
                            type='button'
                          )
                            span.text Excluir
      .files-list.mb-2(v-else)
        .row.px-1
          table-component(
            :columSize='["35%"]'
            :head='tableHead'
            v-if='!isEmptyItems'
          )
            template(v-for='item in rootItemsCleaner')
              tr.table-row(:key='item.id' @click='openItem(item)')
                td.align-middle.subject
                  icon.me-3(:class='[itemIcon(item)]' :name='itemIcon(item)')
                  span.text.fs-6
                    span(v-if='item.name')
                      | {{ fileName(item) }}
                    span(v-else) (sem titulo)
                td.align-middle(style='padding-left: 13px')
                  span {{ item.created_at | dateTime }}
                td.align-middle
                  span(v-if='!item.is_directory')
                    | {{ item.size | fileSize }}
                  span(v-else)
                    | --
                td.action.text-end
                  .dropdown
                    button#file-list-actions-dropdown.btn-actions.dropdown-toggle(
                      @click.stop=''
                      aria-expanded='false'
                      data-bs-toggle='dropdown'
                      type='button'
                    )
                      icon.fs-5(name='ellipsis-vertical')
                    ul.dropdown-menu(
                      aria-labelledby='file-list-actions-dropdown'
                    )
                      li
                        button.dropdown-item(
                          @click.stop='renameItem(item)'
                          type='button'
                        )
                          span.text Renomear
                      li
                      button.dropdown-item(
                        @click.stop='moveTo(item)'
                        type='button'
                      )
                        span.text Mover para
                      li
                        button.dropdown-item(
                          @click='download(item)'
                          type='button'
                        )
                          span.text Baixar
                      li
                        button.dropdown-item(
                          @click.stop='deleteItem(item)'
                          type='button'
                        )
                          span.text Excluir
              tr.spacer

      .text-center.mt-5(v-if='isEmptyItems && isSearching')
        img.img(
          src='/img/ilustrations/no-contacts-found.svg'
          style='max-height: 260px'
        )
        p.mt-4 Não encontramos resultados para sua pesquisa.

      .text-center.mt-5(v-if='isEmptyItems && !isSearching && !parent')
        img.img(
          src='/img/ilustrations/storage-no-items.svg'
          style='max-height: 260px'
        )
        p.mt-4 Você ainda não criou pastas ou documentos.
          br
          | Comece criando uma agora!

      .text-center.mt-5(v-if='isEmptyItems && !isSearching && parent')
        img.img(
          src='/img/ilustrations/no-contacts.svg'
          style='max-height: 260px'
        )
        p.mt-4 Essa pasta está vazia.

      .btn-space.ms-auto.d-block.d-sm-block.d-md-none(v-if='isEmptyItems')
        .dropdown-stylized.dropdown-stylized--with-border
          button.w-100.d-flex(
            aria-expanded='false'
            data-bs-toggle='dropdown'
            type='button'
          )
            span.text.flex-grow-1 Novo
            icon.arrow(name='angle-down')
          ul.dropdown-menu(aria-labelledby='file-list-actions-dropdown')
            li
              button.dropdown-item(
                :disabled='canNotBeCreateFolders'
                data-bs-target='#new-storage-folder-modal'
                data-bs-toggle='modal'
                type='button'
              )
                icon(name='folder')
                span.text Pasta
            li
              button.dropdown-item(
                data-bs-target='#file-loader-modal'
                data-bs-toggle='modal'
                type='button'
              )
                icon(name='file-arrow-up')
                span.text Subir arquivo
  delete-folder-modal(
    :folderId='selectedItem.id'
    @deleted='loadItems'
    ref='deleteFolderModal'
  )

  delete-archive-modal(
    :archiveId='selectedItem.id'
    @deleted='loadItems'
    ref='deleteArchiveModal'
  )

  rename-folder-modal(
    :folder='selectedItem'
    @renamed='loadItems'
    ref='renameFolderModal'
  )

  rename-archive-modal(
    :file='selectedItem'
    @renamed='loadItems'
    ref='renameArchiveModal'
  )

  new-storage-folder-modal(@created='loadItems' ref='new-storage-folder-modal')

  select-folder-modal(
    :enableRootDirectory='true'
    @newFoderCreated='loadItems'
    @selected-folder='onSelectedFolder'
    ref='selecFolderModal'
  )

  file-loader-modal(
    :allowed-file-type='["application/pdf"]'
    :folder-id='folderId'
    :max-file-size-mb='25'
    :source-storage='false'
    @finish-process='loadItems'
    ref='fileLoaderModal'
  )
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import StorageHttp from '@/services/StorageHttp';
import Modal from '@/ui/molecules/Modal.vue';
import FileLoaderModal from '@/ui/organisms/modals/FileLoaderModal.vue';
import debounce from 'debounce';
import DeleteArchiveModal from '@/modules/business/components/organisms/modals/DeleteArchiveModal.vue';
import DeleteFolderModal from '@/modules/business/components/organisms/modals/DeleteFolderModal.vue';
import RenameFolderModal from '@/modules/business/components/organisms/modals/RenameFolderModal.vue';
import RenameArchiveModal from '@/modules/business/components/organisms/modals/RenameArchiveModal.vue';
import TableComponent from '@/ui/organisms/Table.vue';
import NewStorageFolderModal from '@/modules/account/components/organisms/modals/NewStorageFolderModal.vue';
import FileSaver from 'file-saver';
import SelectFolderModal from '@/ui/organisms/modals/SelectFolderModal.vue';
import OrganizationsHttp from '@/modules/business/services/http/organizations/OrganizationsService';

export default {
  components: {
    Icon,
    Modal,
    FileLoaderModal,
    DeleteArchiveModal,
    DeleteFolderModal,
    RenameFolderModal,
    RenameArchiveModal,
    TableComponent,
    NewStorageFolderModal,
    SelectFolderModal,
  },
  props: {
    folderId: String,
  },

  data() {
    return {
      parent: null,
      items: {
        data: [],
        links: {},
        meta: {},
      },
      selectedItem: {},
      breadCrumbs: {
        parent: null,
        current: null,
      },
      queryItem: '',
      ViewMode: 'grid',
      hasLoadedItens: false,
      totalOrganizationStorage: 0,
    };
  },
  updated() {
    this.$store.dispatch('page/setTitle', 'Armazenamento');
    this.$store.dispatch('page/setClass', 'storage');
    if (this.parent) {
      if (this.hasParent(this.parent)) {
        this.$store.dispatch('page/setHead', {
          title: this.parent.name,
          action: {
            name: 'business.storage.item',
            params: { itemId: this.parent.parent.id },
          },
        });
      } else {
        this.$store.dispatch('page/setHead', {
          title: this.parent.name,
          action: { name: 'business.storage' },
        });
      }
    } else {
      this.$store.dispatch('page/setHead', { title: 'Armazenamento' });
    }
  },
  async created() {
    try {
      this.$loading(true);
      await this.loadItems();
      await this.fetchOrganizationStats();
      this.$loading(false);
      window.onscroll = () => {
        const { scrollTop, scrollHeight, clientHeight } =
          document.documentElement;
        if (
          scrollTop + clientHeight >= scrollHeight - 5 &&
          this.items.links.next
        ) {
          this.loadItems(this.items.meta.current_page + 1);
        }
      };
    } catch {
      this.$loading(false);
    }
  },
  watch: {
    folderId(id) {
      this.loadItems();
      return id;
    },
    queryItem() {
      this.hasLoadedItens = false;
    },
  },
  computed: {
    canUploadFile() {
      if (
        this.totalOrganizationSpentStorage >= this.totalOrganizationStorageLimit
      ) {
        return false;
      }
      return true;
    },
    totalOrganizationSpentStorage() {
      return this.formatSpentStorage(this.totalOrganizationStorage);
    },
    totalOrganizationStorageLimit() {
      return this.formatStorage(this.storagePlanLimit);
    },
    storagePlanLimit() {
      if (!this.$store.state.subscription.plan.plan) {
        return false;
      }
      const limit = this.$store.state.subscription.plan.plan.limits
        .filter((i) => i.limitation === 'storage')
        .shift();
      if (!limit) {
        return false;
      }
      return limit.quantity;
    },
    tableHead() {
      return [
        { label: 'Nome' },
        { label: 'Data de criação' },
        { label: 'Tamanho do arquivo' },
      ];
    },
    isEmptyItems() {
      return this.items.data.length === 0;
    },
    isSearching() {
      return this.queryItem.length > 0;
    },
    canNotBeCreateFolders() {
      return this.breadCrumbs.current && this.breadCrumbs.current.depth >= 5;
    },
    rootItemsCleaner() {
      if (
        (this.parent === null && !this.isSearching) ||
        (this.parent === null && !this.hasLoadedItens)
      ) {
        return this.items.data.filter((item) => item.parent === null);
      }
      return this.items.data;
    },
  },
  methods: {
    async loadItems(page = 1) {
      const id = this.$route.params.itemId || '';
      this.$loading(true);
      const params = {
        page,
        limit: 100,
        parent: id,
        q: this.queryItem,
        sort: '-is_directory,-created_at',
      };

      if (id) {
        StorageHttp.getItem(id)
          .then((res) => {
            this.parent = res.data;
          })
          .finally(() => {
            this.breadCrumbs.current = this.parent || null;
            this.breadCrumbs.parent = this.parent ? this.parent.parent : null;
          });
      } else {
        this.parent = null;
        this.breadCrumbs.current = null;
        this.breadCrumbs.parent = null;
      }

      this.items.data = [];
      await StorageHttp.list(params).then((res) => {
        if (page === 1) {
          this.items = res.data;
        } else {
          this.items.data = this.items.data.concat(res.data.data);
          this.items.links = res.data.links;
          this.items.meta = res.data.meta;
        }
        this.$refs.selecFolderModal.$refs.filesListMini.getItems();
        this.$loading(false);
      });
    },

    searchItems: debounce(async function searchItems() {
      await this.loadItems();
      this.hasLoadedItens = true;
    }, 300),

    openDir(folder) {
      this.$emit('folder-click', folder);
    },

    formatSpentStorage(quantity) {
      if (quantity === 0) return '0 B';
      const KB = 1024;
      const MB = KB * KB;
      const GB = MB * KB;

      if (quantity >= GB) {
        return `${(quantity / GB).toFixed(2)} GB`;
      } else {
        return `${(quantity / MB).toFixed(2)} MB`;
      }
    },
    formatStorage(quantity) {
      return quantity >= 1000 ? `${quantity / 1000} GB` : `${quantity} MB`;
    },

    deleteItem(item) {
      this.selectedItem = item;
      if (item.is_directory) {
        this.$refs.deleteFolderModal.$refs.modal.open();
      } else {
        this.$refs.deleteArchiveModal.$refs.modal.open();
      }
    },

    createdFolder() {
      this.loadItems(this.$route.params.itemId);
    },

    selectedFiles() {
      this.$refs.fileLoaderModal.$refs.modal.close();
      this.loadItems();
    },

    renameItem(item) {
      this.selectedItem = item;
      if (item.is_directory) {
        this.$refs.renameFolderModal.$refs.modal.open();
      } else {
        this.$refs.renameArchiveModal.$refs.modal.open();
      }
    },

    changeViewMode(newViewMode) {
      if (this.ViewMode === 'grid' && newViewMode == 'list') {
        this.ViewMode = 'list';
        return;
      }
      if (this.ViewMode === 'list' && newViewMode == 'grid') {
        this.ViewMode = 'grid';
        return;
      }
      return;
    },

    itemIcon(item) {
      if (item.is_directory) {
        return 'folder';
      }

      if (item.is_signed) {
        return 'file-contract';
      }

      return 'file';
    },

    openItem(item) {
      if (item.is_directory) {
        this.openDir(item);
      } else {
        window.open(item.links.display);
      }
    },
    download(item) {
      this.$loading(true);
      StorageHttp.supressDefault = true;
      StorageHttp.download(item.id)
        .then((response) => {
          FileSaver.saveAs(response.data, item.name);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            const message = item.is_directory
              ? 'Pasta não encontrada'
              : 'Arquivo não encontrado';
            alert.fireAlert(`Erro na operação. ${message}.`, {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
              icon: 'triangle-exclamation',
            });
          }
        })
        .finally(() => {
          StorageHttp.supressDefault = false;
          this.$loading(false);
        });
    },
    hasParent(item) {
      return item.parent !== null;
    },
    fileName(item) {
      if (!item.is_directory) {
        const [name] = item.name.split('.');
        return name;
      }
      return item.name;
    },
    moveTo(item) {
      this.selectedItem = item;
      this.$refs.selecFolderModal.$refs.modal.open();
    },
    async onSelectedFolder(folder) {
      const id = folder ? folder.id : null;
      StorageHttp.moveItem(this.selectedItem.id, id).then(() => {
        this.loadItems();
      });
    },
    fetchOrganizationStats() {
      const selected = this.$store.state['organizations'].selected;
      OrganizationsHttp.getStats(selected.id).then((res) => {
        this.totalOrganizationStorage = res.data.invitations + res.data.storage;
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.files-list-component
  .files-list__folders__item
    .dropdown-item
      color: rgba(0,74,98,0.7) !important
    .dropdown-item
      background-color: #fff
  .name-items
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  .card
    background: #FFFFFF
    border: 1px solid #CCCCCC
    box-sizing: border-box
    border-radius: 3px
  .folder-blank
    color: #01AFEC !important
  .file
    color: #CCCCCC !important
  .file-contract
    color: #013D52 !important
  .table-row
    background: #FFFFFF
    border: 1px solid #CCCCCC
    box-sizing: border-box
    td
      padding: 5px
  .dropdown-stylized--with-border
    height: max-content
    button
      display: block
      line-height: 1em
    .icon
      opacity: 1
      &.arrow
        margin: auto 0.875rem !important
        height: auto
    span
      font-weight: 600
    li
      span.text
        border: none
        height: initial
        line-height: initial
        margin: initial
        padding-right: 1em
        font-size: .9em
  .card-body
    padding: 10px
  .files-list
    .col--file-preview
      display: none
  .files-list--cards
    .col--file-preview
      display: block
  .spacer
    border: none !important
    height: 0.938rem
  .dropdown-stylized--with-border
    width: 100%
  .folder
    color: #01AFEC
  .file-contract
    color: #013D52
  .storage-limit
    min-width: 200px
    height: fit-content
    margin-top: .5rem
    margin-right: 1.5rem
    position: relative
    font-size: .9em
    .progress, .text
      margin-left: 1.5rem
    .progress
      height: .85em
      border-radius: 1rem
    .text
      font-size: .9em
    .icon
      top: -2px
      position: absolute
@media screen and (max-width: 768px)
  .top-bar
    #col-1
      order:2
      margin-top: 0.938rem
    #col-2
      order:1
    .search-n-filter
      .search-block
        width: 270px !important
    flex-direction: column
    .btn-space
      width: 100%
button.dropdown-item:disabled
  opacity: 0.7
</style>
