<template lang="pug">
div
  .row
    .col-12(v-if='pendingInvoices')
      .card.card--plan-details.card-warning(
        style='margin-top: 2rem; margin-bottom: 1rem'
      )
        .card-body
          .data.d-flex.justify-content-between
            .detail.d-flex.flex-row.align-items-center
              .text(style='color: #202020; font-size: 16px') Complete seu upgrade!
            .actionStatus.d-flex.flex-row.align-items-center
              .status
                .detail.d-flex.flex-row.align-items-center
                  .type.card-title
                    i.icon.fas.fa-fw.fa-leaf
                    span.text {{ planNamePedingInvoices }}
                  .price
                    span.money-cipher R$
                    span.money-number {{ planPricePendingSubscription[0] }}
                    span.money-cents ,{{ planPricePendingSubscription[1] }}/{{ pendingSubscriptionType }}
              .action
                button.btn.btn-tiny.btn-primary(
                  @click='payPendingSubscription()'
                ) Pagar
                button.btn-link(
                  @click='cancelPendingSubscription()'
                  style='padding-left: 1.5rem'
                ) Cancelar
    .col-12
      .component-title.d-flex.justify-content-between
        span.text Plano atual
        span
          button.btn-link(@click='cancelPlan()' v-if='canCancelPlan')
            .text Cancelar plano
      .card.card--plan-details
        .card-body
          .data.d-flex.justify-content-between
            .detail.d-flex.align-items-center
              .type.card-title
                span.text {{ plan.name }}
              .price
                span.money-cipher R$
                span.money-number {{ planPrice[0] }}
                span.money-cents ,{{ planPrice[1] }}/{{ subscriptionType }}
            .actionStatus.d-flex.align-items-center
              .status
                .alert-simple(:class='planStatus.class' role='alert')
                  icon(:name='planStatus.icon' v-if='planStatus.icon')
                  span.text {{ planStatus.name }}
              .action
                button.btn.btn-primary(
                  @click='upgrade()'
                  type='button'
                  v-if='canUpgradePlan'
                ) Fazer Upgrade
                button.btn.btn-primary(
                  @click='reactivePlan()'
                  type='button'
                  v-if='canReactivatePlan'
                ) Reativar Plano
    .col-12(v-if='hasActiveCreditCard')
      .component-title
        span.text Cartão cadastrado
      .card.card--plan-details
        .card-body
          .data.d-flex.justify-content-between
            .detail.d-flex.align-items-center
              .credicard-flag.card-title--creditcard
                span.text {{ creditCard.cardBrand }}
                .skeleton.skeleton-lg(v-if='credicardLoading')
              .credicard-details
                .skeletonData(v-if='credicardLoading')
                  .number-card.d-flex
                    span.text ****
                    .skeleton.skeleton-sm.ms-2
                  .expirationDate.d-flex
                    span.text(:style='colorExpirationDate') Expira em
                    .skeleton.skeleton-md.ms-2
                .data-credicard-details(v-else) 
                  .text **** {{ creditCard.cardLast4Digits }}
                  .text(:style='colorExpirationDate') Expira em {{ expirationDate }}
            .action
              button.btn.btn-thin(@click='changeCreditCard()' type='button') Trocar Cartão
          .information(v-if='alertUpcomingDueDate')
            .alert-simple.alert-simple-warning(
              :class='alertUpcomingDueDate.class'
              role='alert'
            )
              icon(name='circle-exclamation')
              span.text {{ alertUpcomingDueDate.text }}
    .col-12(v-if='hasInvoices')
      .component-title
        span.text Faturas
      .card.card--rounded
        .card-body
          .alert-simple.alert-simple--bigger(
            :class='invoiceAlert.class'
            role='alert'
            v-if='invoiceAlert'
          )
            i.icon.fas.fa-fw.fa-leaf
            span.text(v-html='invoiceAlert.text')
          plan-details-card-table(:data='invoices')
          simple-paginator(
            :payload='invoicesPagination'
            @navigate='loadData'
            style='margin-top: 0.625rem'
          )
  CancelSubscriptionModal(ref='cancelSubscriptionModal')
</template>

<script>
import PlanDetailsCardTable from '@/modules/account/components/organisms/card-table/PlanDetailsCardTable.vue';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import BillingHttp from '@/services/BillingHttp';
import Icon from '@/ui/atoms/Icon.vue';
import moment from 'moment';
import CancelSubscriptionModal from '@/modules/account/components/organisms/modals/CancelSubscriptionModal.vue';

export default {
  name: 'PlanDetails',
  components: {
    Icon,
    PlanDetailsCardTable,
    SimplePaginator,
    CancelSubscriptionModal,
  },
  data() {
    return {
      invoices: {},
      pendingInvoices: {} || null,
      invoicesPagination: {},
      filters: {
        search: '',
      },
      credicardLoading: true,
    };
  },
  props: {
    plan: Object,
    creditCard: [Object, null],
    planStatus: Object,
  },
  async created() {
    this.$loading(true);
    try {
      await this.loadData();
    } finally {
      this.$loading(false);
    }
  },
  watch: {
    creditCard(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.credicardLoading = false;
      }
    },
  },
  computed: {
    planNamePedingInvoices() {
      if (this.hasPedingInvoices) {
        return this.pendingInvoices.plan.name;
      }
      return '';
    },
    isFreePlan() {
      return this.$store.state.subscription.plan.plan.value_cents === 0
        ? true
        : false;
    },
    canCancelPlan() {
      return this.subscriptionState !== 'cancelled' && !this.isFreePlan;
    },
    canUpgradePlan() {
      return this.planStatus.type !== 'cancelled' || this.isFreePlan;
    },
    canReactivatePlan() {
      return this.planStatus.type === 'cancelled' && !this.isFreePlan;
    },
    hasActiveCreditCard() {
      return this.creditCard && this.planStatus.type !== 'cancelled';
    },
    hasInvoices() {
      return this.invoices.length > 0;
    },
    hasPedingInvoices() {
      return this.pendingInvoices.length > 0;
    },
    subscriptionState() {
      return this.$store.state.subscription.plan.state;
    },
    invoiceAlert() {
      if (this.hasInvoices) {
        const amount = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(this.$store.state.subscription.plan.plan.value_cents / 100);
        const nextRecurrence = moment(
          this.$store.state.subscription.plan.next_recurrence_at
        ).format('DD/MM/YYYY');
        return this.subscriptionState === 'recurring'
          ? {
              text: `Próxima fatura: <b>${amount} em ${nextRecurrence}</b>`,
              class: 'alert-simple-success',
            }
          : {
              text: `<b>Sua última fatura venceu em: ${moment(
                this.invoices[0].due_date
              ).format('DD/MM/YYYY')}</b>`,
              class: 'alert-simple-danger',
            };
      }
      return null;
    },
    planPrice() {
      return this.breakPriceInPieces(this.plan.value_cents);
    },
    planPricePendingSubscription() {
      return this.breakPriceInPieces(this.pendingInvoices.value.amount);
    },
    expirationDate() {
      if (this.creditCard.cardExpiration) {
        const [year, month] = this.creditCard.cardExpiration.split('/');
        return `${month}/${year}`;
      }
      return null;
    },
    colorExpirationDate() {
      if (
        this.alertUpcomingDueDate &&
        this.alertUpcomingDueDate.class == 'alert-simple-danger'
      ) {
        return { color: '#DC342B' };
      }
      return null;
    },
    alertUpcomingDueDate() {
      if (this.creditCard.cardExpiration) {
        const [year, month] = this.creditCard.cardExpiration
          .split('/')
          .map(Number);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;

        const totalMonthsDiff =
          (year - currentYear) * 12 + (month - currentMonth);

        if (totalMonthsDiff === 1 || totalMonthsDiff === 0) {
          return {
            text: 'Seu cartão vai expirar em breve. Atualize para evitar a interrupção do seu plano.',
            class: 'alert-simple-warning',
          };
        } else if (totalMonthsDiff < 0) {
          return {
            text: 'Seu cartão expirou. Atualize agora para reativar o serviço!',
            class: 'alert-simple-danger',
          };
        } else {
          return false;
        }
      }
      return null;
    },
    subscriptionType() {
      return this.plan.interval == 12 ? 'ano' : 'mês';
    },
    pendingSubscriptionType() {
      return this.pendingInvoices.plan.interval == 12 ? 'ano' : 'mês';
    },
  },
  methods: {
    async loadData(page = 1) {
      this.$loading(true);
      const params = new URLSearchParams({
        q: this.filters.search,
        page,
      });
      const invoices = await BillingHttp.getInvoices(params);
      this.invoices = invoices.data.data;
      this.invoicesPagination = invoices.data;
      try {
        const pendingInvoices = await BillingHttp.getPendingInvoices();
        this.pendingInvoices = pendingInvoices.data;
      } catch (error) {
        this.pendingInvoices = null;
      }
      this.$loading(false);
    },
    breakPriceInPieces(price) {
      return (price / 100).toFixed(2).split('.');
    },
    upgrade() {
      this.$router.push('/account/billing/plan?tab=plans');
    },
    async cancelPlan() {
      this.$refs.cancelSubscriptionModal.$refs.modal.open();
    },
    async cancelPendingSubscription() {
      this.$loading(true);
      try {
        await BillingHttp.cancelPendingSubscription();
      } finally {
        window.location.reload();
        this.$loading(false);
      }
    },
    async reactivePlan() {
      try {
        this.$loading(true);
        const renew = await BillingHttp.reactivatePlan();
        const responseUrl = new URL(this.$route.path, window.location.origin)
          .href;
        window.location.href = `${renew.data.checkout_url}&callBackResponseUrl=${responseUrl}?token=`;
      } catch (e) {
        this.$loading(false);
      }
    },
    async changeCreditCard() {
      try {
        this.$loading(true);
        await BillingHttp.cancelCreditCard();
        const responseUrl = new URL(this.$route.path, window.location.origin)
          .href;
        window.location.href = `${this.invoices[0].checkout_url}&callBackResponseUrl=${responseUrl}?token=`;
      } catch (e) {
        this.$loading(false);
      }
    },
    async payPendingSubscription() {
      this.$loading(true);
      const responseUrl = new URL(this.$route.path, window.location.origin)
        .href;
      window.location.href = `${this.pendingInvoices.checkout_url}&callBackResponseUrl=${responseUrl}?token=`;
    },
  },
};
</script>
<style scoped>
.skeleton-lg {
  height: 24px;
  width: 130px;
}

.skeleton-sm {
  height: 15px;
  width: 40px;
}

.skeleton-md {
  height: 15px;
  width: 50px;
}

.skeleton {
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
