<template lang="pug">
.organization-theme
  h3 Temas
  p Para configurar os temas é necessário criar um subdomínio para sua organização.
  form
    .row
      //- .col-12.col-md-6
        .form-group
          label(for='email') Subdominio*
          .form-control-icon
            icon(name='laptop' :fix-width='true')
            .input-group.mb-3
              input.form-control(type='text' placeholder='Subdominio'
              aria-label='Subdominio' aria-describedby='basic-addon2')
              span#basic-addon2.input-group-text .assine.online
      .col-12
        h6.block-title Logo
        p Insira um arquivo em formato JPG ou PNG com tamanho até 25mb.
        .form-group
          input(
            @change='uploadFile'
            ref='file'
            type='file'
          )
        .card.card-logo-preview(v-if='savedLogo || logo')
          img.card-img-top.logo(:src='logo ? logo : savedLogo')
          .card-body
            .text-end
              button(@click='deleteLogo()' type='button')
                icon(:fix-width='true' name='trash-can')
        h6.block-title Avatar
        p Defina uma cor de fundo para o avatar de sua organização e depois selecione
          |
          | uma cor para as iniciais. <br> Para uma melhor leitura, fique atento ao
          | contraste entre as cores.
      .col-12
        ul.theme-colors-list.list-unstyled
          .row
            .col-12.col-sm-6.col-md-3
              li.list-item
                p Cor de fundo
                input.color-picker.shadow(
                  type='color'
                  v-model='palette.avatar_background_color'
                )
            .col-12.col-sm-6.col-md-3
              li.list-item
                p Iniciais do Avatar
                input.color-picker.shadow(
                  type='color'
                  v-model='palette.avatar_text_color'
                )
            .col-12.col-sm-6.col-md-3
              li.list-item
                p Simulação
                .color-picker
                  .color-theme-simulator.text-center(
                    :style='simulationPaletteStyleAvatar()'
                  ) AO
      .col-12.mt-3
        h6.block-title Cabeçalho
        p Defina uma cor de fundo para o cabeçalho de sua organização e depois
          |
          | selecione uma cor para os elementos do cabeçalho.
          | <br>Para uma melhor leitura, fique atento ao contraste entre as cores.
        ul.theme-colors-list.list-unstyled
          .row
            .col-12.col-sm-6.col-md-3
              li.list-item
                p Cor de fundo
                input.color-picker.shadow(
                  type='color'
                  v-model='palette.navbar_background_color'
                )
            .col-12.col-sm-6.col-md-3
              li.list-item
                p Elementos do cabeçalho
                input.color-picker.shadow(
                  type='color'
                  v-model='palette.navbar_text_color'
                )
            .col-12.col-sm-6.col-md-3
              li.list-item
                p Simulação
                .color-picker.color-theme-simulator.text-center(
                  :style='simulationPaletteStyleNavBar()'
                )
                  icon(:fix-width='true' name='bell')
                  icon.ml-3(:fix-width='true' name='circle-user')
      .col-12.mt-3
        h6.block-title Botões
        p Defina uma cor de fundo para os botões de sua organização e depois
          |
          | selecione uma cor para os textos dos botões.
          | <br>Para uma melhor leitura, fique atento ao contraste entre as cores.
        ul.mt-3.theme-colors-list.list-unstyled
          .row
            .col-12.col-sm-6.col-md-3
              li.list-item
                p Cor de fundo
                input.color-picker.shadow(
                  type='color'
                  v-model='palette.button_background_color'
                )
            .col-12.col-sm-6.col-md-3
              li.list-item
                p Texto do botão
                input.color-picker.shadow(
                  type='color'
                  v-model='palette.button_text_color'
                )
            .col-12.col-sm-6.col-md-3
              li.list-item
                p Simulação
                button.btn(
                  :style='simulationPaletteStyleButton()'
                  type='button'
                )
                  | Botão Personalizado
      .col-12.text-end
        button.btn.btn--cancel-red-text.mt-2(
          data-bs-target='#remove-theme-modal'
          data-bs-toggle='modal'
          style='margin-right: 1rem'
          type='button'
        ) Restaurar tema
        button.mt-2.btn.btn-secondary(
          @click='saveOrganizationTheme'
          type='button'
        ) Salvar
  remove-theme-modal(@refresh='fetchTheme')
</template>
<script>
import WhiteLabelService from '@/modules/business/services/http/organizations/WhiteLabelService';
import RemoveThemeModal from '@/modules/business/components/organisms/modals/RemoveThemeModal.vue';
import alert from '@/modules/account/common/alert';
import Icon from '@/ui/atoms/Icon.vue';
import LocalStorage from '@/services/LocalStorage';
import AppConfig from '@/config';

export default {
  components: {
    Icon,
    RemoveThemeModal,
  },
  props: {
    organization: Object,
  },
  data() {
    return {
      palette: {
        avatar_background_color: '#000000',
        avatar_text_color: '#ffffff',
        navbar_background_color: '#000000',
        navbar_text_color: '#ffffff',
        button_background_color: '#000000',
        button_text_color: '#ffffff',
      },
      file: '',
      logo: '',
    };
  },
  async created() {
    this.$loading(true);
    try {
      this.fetchTheme();
    } finally {
      this.$loading(false);
    }
  },
  computed: {
    savedLogo() {
      if (
        this.$store.state.organizations.selected.logo ===
        `${AppConfig.cdnPath}/assets/images/rebrand-intellisign/intellisign-logo.png`
      ) {
        return false;
      }
      return this.$store.state.organizations.selected.logo;
    },
  },
  methods: {
    simulationPaletteStyleAvatar() {
      return `background-color: ${this.palette.avatar_background_color}; color: ${this.palette.avatar_text_color};`;
    },
    simulationPaletteStyleNavBar() {
      return `background-color: ${this.palette.navbar_background_color}; color: ${this.palette.navbar_text_color};`;
    },
    simulationPaletteStyleButton() {
      return `background-color: ${this.palette.button_background_color}; color: ${this.palette.button_text_color};`;
    },
    async saveOrganizationTheme() {
      this.$loading(true);
      try {
        const data = {
          avatar_background_color: this.palette.avatar_background_color,
          avatar_text_color: this.palette.avatar_text_color,
          navbar_background_color: this.palette.navbar_background_color,
          navbar_text_color: this.palette.navbar_text_color,
          button_background_color: this.palette.button_background_color,
          button_text_color: this.palette.button_text_color,
        };
        WhiteLabelService.update(
          this.$store.state.organizations.selected.id,
          data
        ).then((ret) => {
          this.palette = ret.data;
        });

        if (this.file[0]) {
          const responseLogo = await WhiteLabelService.updateLogo(
            this.$store.state.organizations.selected.id,
            this.file[0]
          );
          this.$store.commit(
            'organizations/setOrganizationLogo',
            responseLogo.data.logo
          );
        }
        this.$store.dispatch('organizations/getSelected');
        alert.fireAlert('Informações salvas com sucesso', {
          classes: 'alert-success',
          styles:
            'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
          icon: 'circle-check',
        });
      } finally {
        this.$loading(false);
      }
    },
    uploadFile() {
      this.file = this.$refs.file.files;
      this.logo = URL.createObjectURL(this.$refs.file.files[0]);
    },
    async deleteLogo() {
      this.$loading(true);
      try {
        await WhiteLabelService.deleteLogo(
          this.$store.state.organizations.selected.id
        );
        this.$refs.file.value = null;
        this.logo = '';
        this.$store.dispatch('organizations/getSelected');
      } finally {
        this.$loading(false);
      }
    },
    async fetchTheme() {
      const whitelabelResult = await WhiteLabelService.list(
        LocalStorage.get('organization')
      );
      this.palette = whitelabelResult.data;
      this.$store.dispatch('organizations/getSelected');
    },
  },
};
</script>
<style lang="stylus">
input[type=file]::file-selector-button
  background: none
  border-color: #013D52
  color: #013D52
  border-radius: .3rem
  font-weight: 600
  font-size: 1rem
  padding: 0.4rem 1.2rem
  margin-right: .3rem
  cursor: pointer
input[type=file]::file-selector-button:hover
  opacity: .8
.card-logo-preview
  margin-top: 1rem
  margin-bottom: 1rem
  width: 168px
</style>
