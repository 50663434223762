<template lang="pug">
.fields-screen
  .row
    envelope-step-bar(:runBeforeNavigate='saveFields' :step='3')
    .col-lg-4.col-xl-3
      .row
        .col-sx-12
          h3.title-font-size.mb-2 Configuração dos assinantes
          .card.card--rounded.shadow-none.recipient-card.mb-4
            .card-body.pb-2
              p.font-size-subtitle.ms-2.mb-1 Selecione o assinante
              .recipient-select
                aol-select(
                  :options='signers'
                  label='name'
                  v-model='signerSelected'
                )
                  template(v-slot:no-options='{}') Nenhum assinante com esse nome
                  template(v-slot:option='{ name, email }')
                    div {{ name }}
                    small {{ email }}
                  template(v-slot:prepend='{}') @
              .recipient-block(v-if='signerSelected')
                p.font-size-subtitle.ms-2.mt-4.mb-2 Campos do assinante
                draggable.drag-area.d-flex.flex-column(
                  :forceFallback='true'
                  :group='{ name: "elements", pull: "clone", put: false }'
                  :list='allUserElements'
                  @end='onDragEnd'
                  @start='onDragStart'
                  draggable='.item'
                )
                  .item.drag-item.badge(
                    :data-element='JSON.stringify(item)'
                    :data-recipient='JSON.stringify(signerSelected)'
                    :key='`content-${index}`'
                    v-for='(item, index) in allUserElements'
                    v-if='checkRecipientElements(item, signerSelected)'
                    v-tooltip:top='item.description'
                  )
                    .item-content.d-flex.align-items-center
                      .icon-background
                        icon(:name='item.icon')
                      .text.field-name.ms-2 {{ item.name }}
                    icon.me-2.fs-5(name='grip-dots-vertical')
        .col-sx-12
          h3.title-font-size.mb-2 Personalizar documento
          .card.card--rounded.shadow-none.recipient-card.mb-5
            .card-body.pb-2
              draggable.drag-area.d-flex.flex-column(
                :forceFallback='true'
                :group='{ name: "elements", pull: "clone", put: false }'
                :list='documentElements'
                draggable='.item'
              )
                .item.drag-item.badge(
                  :data-element='JSON.stringify(item)'
                  :key='`content-${index}`'
                  v-for='(item, index) in documentElements'
                  v-tooltip:top='item.description'
                )
                  .item-content.d-flex.align-items-center
                    .icon-background
                      icon(:name='item.icon')
                    span.text.field-name.ms-2 {{ item.name }}
                  icon.me-2.fs-5(name='grip-dots-vertical')
      //- End - Recipients
    .col-lg-8.col-xl-9
      documents-render(
        :document='documents[0]'
        :documents='documents'
        :elements='elements'
        @deleteElement='onDeleteElement'
        @dropElement='onDropElement'
        @updateElement='onUpdateElement'
        v-if='documents.length > 0'
      )
  envelope-bottom-bar(
    @nextStep='next'
    @saveAndClose='saveAndClose'
    v-if='true'
  )
  warning-save-and-close-envelope-modal(
    @discardChanges='discardChanges'
    @saveAndClose='saveAndClose'
    ref='warningSaveAndCloseEnvelopeModal'
  )
    p.mb-0 Você deseja sair sem salvar os documentos inseridos?
  onboarding-fields-modal(
    @finished='updateOnboarding({ onboardingName: "business_envelope_fields", value: true })'
    ref='onboardingFieldsModal'
  )
</template>
<script>
import Draggable from 'vuedraggable';
import Icon from '@/ui/atoms/Icon.vue';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import DocumentsRender from '@/ui/organisms/DocumentsPDFRender.vue';
import EnvelopeManager from '@/modules/business/services/EnvelopeManager';
import EnvelopeBottomBar from '@/ui/organisms/EnvelopeBottom.vue';
import EnvelopeStepBar from '@/ui/organisms/EnvelopeStepBar.vue';
// eslint-disable-next-line max-len
import WarningSaveAndCloseEnvelopeModal from '@/modules/business/components/organisms/modals/WarningSaveAndCloseEnvelope.vue';
import OnboardingFieldsModal from '@/ui/organisms/modals/OnboardingFieldsModal.vue';
import RecipientType from '@/enums/RecipientType';
import { mapGetters, mapActions } from 'vuex';

export default {
  /* eslint-disable max-len */
  name: 'EnvelopeFields',
  components: {
    DocumentsRender,
    Draggable,
    Icon,
    EnvelopeBottomBar,
    EnvelopeStepBar,
    WarningSaveAndCloseEnvelopeModal,
    OnboardingFieldsModal,
  },
  data() {
    return {
      documents: [],
      recipients: [],
      signerSelected: null,
      nextRoute: null,
      recipientElements: [
        {
          icon: 'signature',
          type: 'signature',
          name: 'Assinatura',
          description: 'Assinatura do destinatário',
          category: 'field',
          required: true,
          resizable: true,
          autoSize: true,
        },
        {
          icon: 'file-contract',
          type: 'initial',
          name: 'Rubrica',
          description: 'Rubrica do destinatário',
          category: 'field',
          settings: true,
          key: 0,
          required: true,
          resizable: true,
          autoSize: true,
        },
      ],
      formElements: [
        {
          icon: 'square-list',
          type: 'dropdown',
          name: 'Opções',
          description: 'Campo de opções',
          category: 'field',
          fieldName: '',
          settings: true,
          key: 0,
          options: [],
          openOnDrop: true,
          resizable: false,
        },
        {
          icon: 'text',
          type: 'text',
          name: 'Texto',
          description: 'Campo de texto',
          category: 'field',
          fieldName: '',
          settings: true,
          key: 0,
          resizable: false,
          openOnDrop: true,
        },
        // {
        //   icon: 'circle',
        //   type: 'radio-group',
        //   name: 'Radio',
        //   category: 'field',
        //   fieldName: '',
        //   settings: true,
        //   openOnDrop: true,
        //   sizeFitContent: true,
        // },
        {
          icon: 'square-check',
          type: 'checkbox',
          name: 'Caixa de seleção',
          description: 'Permitir marcar lista de itens',
          category: 'field',
          fieldName: '',
          settings: true,
          key: 0,
          openOnDrop: true,
          resizable: false,
        },
      ],
      documentElements: [
        {
          icon: 'comment-dots',
          type: 'comment',
          name: 'Anotações',
          description: 'Adicionar anotações',
          category: 'content',
          value: '',
          settings: true,
          key: 0,
          openOnDrop: true,
          resizable: false,
        },
        {
          icon: 'folder-image',
          type: 'image',
          name: 'Imagem',
          description: 'Adicionar imagem',
          category: 'content',
          value: '',
          settings: false,
          key: 0,
          openOnDrop: true,
          resizable: true,
          image: '',
        },
        {
          icon: 'calendar',
          type: 'date',
          name: 'Data',
          description: 'Adicionar data',
          category: 'content',
          value: '',
          settings: true,
          key: 0,
          resizable: false,
          openOnDrop: true,
        },
        // {
        //   icon: 'qrcode',
        //   type: 'validation-qrcode',
        //   name: 'QR Code',
        //   description: 'Autenticar documento com QR Code',
        //   category: 'content',
        //   resizable: true,
        //   openOnDrop: true,
        // },
      ],
      elements: [],
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === 'business.envelope.recipients' ||
      to.name === 'business.envelope.review' ||
      to.name === 'business.documents.draft'
    ) {
      next();
      return;
    }
    this.$refs.warningSaveAndCloseEnvelopeModal.$refs.modal.open();
    this.nextRoute = next;
  },
  created() {
    let pageTitle = 'Editar envelope';
    const self = this;
    if (this.$route.params.isNew) {
      pageTitle = 'Novo envelope';
    }
    this.$store.dispatch('page/setTitle', pageTitle);
    this.$store.dispatch('page/setHead', {
      title: pageTitle,
      action() {
        self.$router.replace({
          name: 'business.envelope.recipients',
          params: {
            id: self.$route.params.id,
            isNew: self.$route.params.isNew,
          },
        });
      },
      help() {
        self.$refs.onboardingFieldsModal.$refs.modal.open();
      },
    });
    this.$loading(false);
  },
  async beforeMount() {
    const res = await EnvelopesService.getExtendedEnvelope(
      this.$route.params.id
    );
    this.recipients = res.data.recipients.map((recipient) => ({
      ...recipient,
      name: recipient.addressees[0].name,
      email: recipient.addressees[0].value,
    }));
    [this.signerSelected] = this.signers;
    this.documents = res.data.documents.map((doc) => {
      const document = doc;
      document.src = document.links.display;
      const elements = EnvelopeManager.extratElementsFromDocument(
        document,
        res.data.recipients
      );
      this.elements = this.elements.concat(elements);
      return document;
    });
  },
  mounted() {
    if (!this.getOnboarding('business_envelope_fields')) {
      this.$refs.onboardingFieldsModal.$refs.modal.open();
    }
  },
  computed: {
    ...mapGetters({ getOnboarding: 'onboardings/getOnboarding' }),
    allUserElements() {
      return this.recipientElements.concat(this.formElements);
    },
    signers() {
      return this.recipients.filter(
        (recipient) => recipient.type === RecipientType.SIGNER
      );
    },
  },
  methods: {
    onDragStart() {
      document.body.style.userSelect = 'none';
    },
    onDragEnd() {
      document.body.style.userSelect = 'auto';
    },
    ...mapActions({ updateOnboarding: 'onboardings/updateOnboarding' }),
    checkRecipientElements(element, recipient) {
      if (
        recipient.type !== 'signer' &&
        element.category === 'field' &&
        ['text', 'signature', 'initial', 'checkbox', 'dropdown'].includes(
          element.type
        )
      ) {
        return false;
      }
      return true;
    },
    onDropElement(element) {
      this.elements.push(element);
    },
    onUpdateElement(element) {
      const elements = this.elements.filter((i) => i.id !== element.id);
      elements.push(element);
      this.$set(this, 'elements', elements);
    },
    onDeleteElement(element, document) {
      if (element.inApi) {
        if (element.category === 'field') {
          EnvelopesService.removeDocumentField(
            this.$route.params.id,
            document.id,
            element.id
          ).then(() => {
            this.elements = this.elements.filter((i) => i.id !== element.id);
          });
        }
        if (element.category === 'content') {
          EnvelopesService.removeDocumentContent(
            this.$route.params.id,
            document.id,
            element.id
          ).then(() => {
            this.elements = this.elements.filter((i) => i.id !== element.id);
          });
        }
      } else {
        this.elements = this.elements.filter((i) => i.id !== element.id);
      }
    },
    async saveFields() {
      if (this.elements.length > 0) {
        this.elements.forEach((el) => {
          const element = el;
          if (element.inApi && !element.updated) {
            return;
          }
          if (element.category === 'content') {
            const data = EnvelopeManager.generateApiContentObject(element);
            this.$loading(true);
            if (element.inApi && element.updated) {
              EnvelopesService.updateDocumentContent(
                this.$route.params.id,
                element.document,
                element.id,
                data
              )
                .then((res) => {
                  element.id = res.data.id;
                  element.inApi = true;
                })
                .finally(() => {
                  this.$loading(false);
                });
            } else {
              EnvelopesService.addDocumentContent(
                this.$route.params.id,
                element.document,
                data
              )
                .then((res) => {
                  element.id = res.data.id;
                  element.inApi = true;
                })
                .finally(() => {
                  this.$loading(false);
                });
            }
          }
          if (element.category === 'field') {
            this.$loading(true);
            const data = EnvelopeManager.generateApiFieldObject(element);
            if (element.inApi && element.updated) {
              EnvelopesService.updateDocumentField(
                this.$route.params.id,
                element.document,
                element.id,
                data
              )
                .then((res) => {
                  element.id = res.data.id;
                  element.inApi = true;
                })
                .finally(() => {
                  this.$loading(false);
                });
            } else {
              EnvelopesService.addDocumentField(
                this.$route.params.id,
                element.document,
                data
              )
                .then((res) => {
                  element.id = res.data.id;
                  element.inApi = true;
                })
                .finally(() => {
                  this.$loading(false);
                });
            }
          }
        });
      }
    },
    async next() {
      await this.saveFields();
      this.$router.replace({
        name: 'business.envelope.review',
        params: { id: this.$route.params.id, isNew: this.$route.params.isNew },
      });
    },
    async saveAndClose() {
      await this.saveFields();
      if (this.nextRoute) {
        this.nextRoute();
        return;
      }
      this.$router.push({ name: 'business.documents.draft' });
    },
    discardChanges() {
      if (this.nextRoute) {
        this.nextRoute();
        return;
      }
      this.$router.go(-1);
    },
  },
  filters: {
    recipientType: (type) => RecipientType.getText(type),
  },
};
</script>
<style lang="stylus">
.fields-screen
  .title-font-size
    font-size: 14px
  .side-bar
    max-width: 450px
    .card
      margin-bottom: 1rem
  .drag-item
    display: flex
    align-items: center
    justify-content: space-between
    font-size: .9em
    font-weight: 600
    cursor: move
    background-color: #E6F0F4
    color: #013D52
    padding: 6px 0px 6px 0px
    margin-bottom: .5rem
    border-radius: 50px
    .icon
      font-size: 15px
    .icon-background
      margin-left: 6px
      padding: 4px
      background-color: #FFFFFF
      border-radius: 50px
    .field-name
      font-size: 1.2em
      font-weight: 500;
  .recipient-select
    .vs__selected-options
      margin-left: 0
    .vs__dropdown-toggle
      padding-left: 2.4em
    .v-select
      position: relative
      &::before
        content: ""
        position: absolute
        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fal' data-name='user' class='svg-inline--fa fa-user fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23013D52' d='M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z'%3E%3C/path%3E%3C/svg%3E")
        background-repeat: no-repeat
        width: 14px
        height: 14px
        background-size: 12px
        top: 48%
        left: 14px
        margin-top: -7px
        color: #013D52
        font-size: 1rem
        padding: 9px 10px
        font-weight: bold
        display: block
</style>
