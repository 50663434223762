<template lang="pug">
ValidationObserver(ref='form' v-slot='{ valid }')
  envelope-step-bar(:runBeforeNavigate='saveContact' :step='2')
  .alert-template.w-100.d-flex.flex-column.justify-content-center.mb-3(
    v-if='hasEmptyRecipients && hasRecipient'
  )
    span.ms-3 Atualize os campos abaixo com os novos destinatários.
  form(v-if='hasRecipient')
    //- Start - Recipients
    .mb-3(v-if='recipients.length > 1 || recipientsOrder.ordered')
      .form-check.form-switch(style='line-height: 1.9em')
        label.form-check-label(for='order') Ordem de assinatura
        input#order.ms-3.form-check-input(
          @change='recipientsOrder.updated = true'
          type='checkbox'
          v-model='recipientsOrder.ordered'
        )
    draggable(
      :animation='200'
      :disabled='!recipientsOrder.ordered'
      :forceFallback='true'
      :list='recipients'
      :preventOnFilter='false'
      @change='recipientsOrder.updated = true'
      filter='.disableDraggable'
      ghost-class='moving-card'
    )
      .card.card--rounded.flex-row.mb-5(
        :key='recipient.id'
        v-bind:style='{ cursor: recipientsOrder.ordered ? "move" : "default" }'
        v-for='(recipient, index) in recipients'
      )
        .order-recipient.d-flex.flex-column(v-if='recipientsOrder.ordered')
          .mt-1
            icon.icon(name='grip-dots-vertical')
          .text {{ index + 1 }}°
        .card-body
          .row
            .col-12.col-md-6.form-group
              ValidationProvider(
                :custom-messages='customMessagesName'
                :vid='"vp-name-" + index'
                name='nome'
                rules='required'
                v-slot='{ errors }'
              )
                label(for='name') Nome*
                .form-control-icon
                  icon(name='user')
                  input#name.form-control.disableDraggable(
                    :class='{ "border border-danger": errors[0] }'
                    :disabled='inputDisabled(recipient)'
                    @change='recipient.updated = true'
                    autocomplete='off'
                    placeholder='Digite o nome do destinatário'
                    type='text'
                    v-model='recipient.name'
                  )
                small.align-items-center.text.text-danger.d-flex(
                  v-if='errors.length > 0'
                )
                  icon(name='xmark')
                  span.text.ms-2 {{ errors[0] }}
              .form-check.mt-2(v-if='recipient.requestedAction === "signer"')
                input.form-check-input(
                  :id='`ask-by-photo-${recipient.id}`'
                  @change='recipient.updated = true'
                  type='checkbox'
                  v-model='recipient.photo'
                  value='true'
                )
                label.form-check-label(:for='`ask-by-photo-${recipient.id}`') Exigir foto
            .col-12.col-md-6.form-group
              ValidationProvider(
                :custom-messages='customMessagesRecipient'
                :vid='"vp-recipient-" + index'
                name='recipient'
                rules='required'
                v-slot='{ errors }'
              )
                label(for='name') Tipo de destinatário*
                div(@click='onClickSignatureType(recipient)')
                  aol-select.disableDraggable(
                    :class='{ "border border-danger": errors[0] }'
                    :options='typeOfRecipients'
                    :reduce='(type) => type.id'
                    @input='recipient.updated = true'
                    label='title'
                    placeholder='Selecione uma opção '
                    v-model='recipient.requestedAction'
                  )
                    template(v-slot:no-options='{}')
                      span.text Nenhum tipo de destinatário com esse nome
                    template(v-slot:selected-option='{ title, icon }')
                      icon(:name='icon')
                      span.text {{ title }}
                    template(v-slot:option='{ title, icon }')
                      icon(:name='icon')
                      span.text {{ title }}
                    template(v-slot:prepend='{}')
                      icon(:name='icon')
                small.align-items-center.text.text-danger.d-flex(
                  v-if='errors.length > 0'
                )
                  icon(name='xmark')
                  span.text.ms-2 {{ errors[0] }}
            .col-12.col-md-6.form-group
              label(for='email') {{ shippingTitle }}
              label(v-if='hasFeatureWhatsapp')
                input.mx-2.form-check-input(
                  checked
                  type='radio'
                  v-model='recipient.shippingType'
                  value='email'
                )
                | Endereço de e-mail
                input.mx-2.form-check-input(
                  type='radio'
                  v-model='recipient.shippingType'
                  value='whatsapp'
                )
                | Whatsapp
              ValidationProvider(
                :custom-messages='customMessagesEmail'
                :vid='"vp-shipping-" + index'
                key='email'
                name='e-mail'
                rules='required|email'
                v-if='recipient.shippingType !== "whatsapp"'
                v-slot='{ errors }'
              )
                .form-control-icon
                  icon(name='at')
                  input#email.form-control.disableDraggable(
                    :class='{ "border border-danger": errors[0] }'
                    :disabled='inputDisabled(recipient)'
                    @change='recipient.updated = true'
                    autocomplete='off'
                    placeholder='Digite o e-mail do destinatário'
                    v-model='recipient.email'
                  )
                small.align-items-center.text.text-danger.d-flex(
                  v-if='errors.length > 0'
                )
                  icon(name='xmark')
                  span.text.ms-2 {{ errors[0] }}
              ValidationProvider(
                :custom-messages='customMessagePhone'
                :key='"whatsapp-" + index'
                :vid='"vp-shipping-" + index'
                name='phone'
                rules='required|intBrazilianPhone'
                v-if='recipient.shippingType === "whatsapp"'
                v-slot='{ errors }'
              )
                .form-control-icon
                  input-tel#phone.form-control.disableDraggable(
                    :class='{ "border border-danger": errors[0] }'
                    :disabled='inputDisabled(recipient)'
                    :value='recipient.phone'
                    @input='recipient.updated = true'
                    autocomplete='off'
                    placeholder='(00) 0000-0000'
                    type='text'
                    v-mask='"## #####-####"'
                    v-model='recipient.phone'
                  )
                small.align-items-center.text.text-danger.d-flex(
                  v-if='errors.length > 0'
                )
                  icon(name='xmark')
                  span.text.ms-2 {{ errors[0] }}
            .col-12.col-md-6.form-group(
              v-if='recipient.requestedAction === "signer"'
            )
              label(for='name') Tipo de assinatura*
              aol-select(
                :options='typeOfAuth'
                :reduce='(type) => type.id'
                @input='recipient.updated = true'
                label='title'
                placeholder='Selecione uma opção'
                v-model='recipient.authType'
              )
                template(v-slot:no-options='{}') Nenhum tipo de assinatura com esse nome
                template(v-slot:selected-option='{ title, icon, id }')
                  icon(:name='icon')
                  span.text {{ title }}
                template(v-slot:option='{ title, icon, id }')
                  icon(:name='icon')
                  span.text {{ title }}
                template(v-slot:prepend='{}')
                  icon(:name='icon')
            .col-12.form-group.text-end.mb-0
              button.link--delete-recipient(
                @click='removeRecipientConfirmation(recipient)'
                type='button'
              )
                icon.default(name='trash')
                icon.hover(name='trash')
                span.ms-2.text Excluir destinatário
  .text-center.mt-5(v-else)
    img.img(src='/img/ilustrations/no-recipient.svg')
    p.mt-4 Você ainda não adicionou um tipo de destinatário. Vamos começar?
  .d-flex(
    :style='hasRecipient ? "margin-top: -23px" : ""'
    style='margin-bottom: 90px'
    v-bind:class='hasRecipient ? "justify-content-between" : "justify-content-center"'
  )
    .dropdown-stylized.dropdown-stylized--with-border
      button(
        aria-expanded='false'
        data-bs-toggle='dropdown'
        type='button'
      )
        span.text Adicionar
        icon.arrow(name='angle-down')
      .dropdown-menu(aria-labelledby='productsDropdown')
        ul
          li
            button.dropdown-item(@click='addEmptyRecipient' type='button')
              .text Destinatário individual
          li
            button.dropdown-item(@click='addUserAsRecipient' type='button') Me adicionar
          li
            button.dropdown-item(
              data-bs-target='#contact-loader-modal'
              data-bs-toggle='modal'
              type='button'
            ) Lista de contatos

  envelope-bottom-bar(
    :disableButtonNextStep='!valid || !hasRecipient'
    @nextStep='nextStep'
    @saveAndClose='saveAndClose'
  )
  contact-loader-modal#contact-loader-modal(
    @selected-contacts='addContactsAsRecipient'
  )
  no-subscriber-modal(ref='noSubscriberModal')
  warning-save-and-close-envelope-modal(
    @discardChanges='discardChanges'
    @saveAndClose='saveAndClose'
    ref='warningSaveAndCloseEnvelopeModal'
  )
    p.mb-0 Você deseja sair sem salvar os destinatários inseridos ou alterados?
  onboarding-recipient-modal(
    @finished='updateOnboarding({ onboardingName: "business_envelope_recipient", value: true })'
    ref='onboardingRecipientModal'
  )
  delete-recipient-modal(
    @process='removeRecipient(selectedRecipientRemove)'
    ref='deleteRecipientModal'
  )
  change-type-signer-modal(ref='changeTypeSignerModal')
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import ContactLoaderModal from '@/ui/organisms/modals/ContactLoaderModal.vue';
import NoSubscriberModal from '@/modules/business/components/organisms/modals/NoSubscriberModal.vue';
import OnboardingRecipientModal from '@/modules/business/components/organisms/modals/OnboardingRecipientModal.vue';
import Draggable from 'vuedraggable';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import RecipientType from '@/enums/RecipientType';
import EnvelopeBottomBar from '@/ui/organisms/EnvelopeBottom.vue';
import ContactService from '@/modules/account/services/http/ContactsService';
// eslint-disable-next-line max-len
import WarningSaveAndCloseEnvelopeModal from '@/modules/business/components/organisms/modals/WarningSaveAndCloseEnvelope.vue';
import EnvelopeStepBar from '@/ui/organisms/EnvelopeStepBar.vue';
import DeleteRecipientModal from '@/modules/business/components/organisms/modals/DeleteRecipientModal.vue';
import ChangeTypeSignerModal from '@/modules/business/components/organisms/modals/ChangeTypeSignerModal.vue';
import { mapGetters, mapActions } from 'vuex';
import InputTel from '@/ui/molecules/InputTel.vue';

const recipientModel = {
  name: '',
  email: '',
  requestedAction: '',
  authType: 'default',
  isInvalid: false,
  errors: {},
  inServer: false,
};

export default {
  name: 'RecipientsEnvelopePage',
  components: {
    ContactLoaderModal,
    Icon,
    Draggable,
    NoSubscriberModal,
    EnvelopeBottomBar,
    WarningSaveAndCloseEnvelopeModal,
    EnvelopeStepBar,
    OnboardingRecipientModal,
    DeleteRecipientModal,
    ChangeTypeSignerModal,
    InputTel,
  },
  data() {
    return {
      hasEmptyRecipients: false,
      recipientsOrder: {
        updated: false,
        ordered: false,
      },
      envelopeState: '',
      recipients: [],
      documents: [],
      error: false,
      loading: false,
      nextRoute: null,
      selectedRecipientRemove: {},
      customMessagesName: {
        required: 'É necessário inserir um nome',
      },
      customMessagesEmail: {
        required: 'É necessário inserir um e-mail',
        email: 'É necessário inserir um e-mail válido',
      },
      customMessagePhone: {
        required: 'É necessário inserir um telefone',
      },
      customMessagesRecipient: {
        required: 'É necessário selecionar um tipo de destinatário',
      },
      typeOfRecipients: [
        {
          id: 'signer',
          title: 'Assinante',
          icon: 'signature',
        },
        {
          id: 'approver',
          title: 'Aprovador',
          icon: 'user-check',
        },
        {
          id: 'carbon-copy',
          title: 'Visualizador',
          icon: 'eye',
        },
      ],
      typeOfAuth: [
        {
          id: 'simple',
          title: 'Assinatura eletrônica simples',
          icon: 'file-contract',
        },
        {
          id: 'qualified',
          title: 'Assinatura eletrônica qualificada',
          icon: 'shield',
        },
      ],
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === 'business.envelope.documents' ||
      to.name === 'business.envelope.fields' ||
      to.name === 'business.documents.draft'
    ) {
      next();
      return;
    }
    this.$refs.warningSaveAndCloseEnvelopeModal.$refs.modal.open();
    this.nextRoute = next;
  },
  watch: {
    recipientsCopy(newArray, oldArray) {
      if (newArray.length === 1) {
        this.recipientsOrder.ordered = false;
        return;
      }

      if (oldArray.length > newArray.length) {
        return;
      }

      if (oldArray.length <= 1 && newArray.length >= 2) {
        this.recipientsOrder.ordered = true;
        return;
      }
    },
  },
  created() {
    let pageTitle = 'Editar envelope';
    const self = this;
    if (this.$route.params.isNew) {
      pageTitle = 'Novo envelope';
    }
    this.updateOnboarding({
      onboardingName: 'business_envelope_recipient',
      value: true,
    });
    this.$store.dispatch('page/setTitle', pageTitle);
    this.$store.dispatch('page/setHead', {
      title: pageTitle,
      action() {
        self.$router.replace({
          name: 'business.envelope.documents',
          params: {
            id: self.$route.params.id,
            isNew: self.$route.params.isNew,
          },
        });
      },
      help() {
        self.$refs.onboardingRecipientModal.$refs.modal.open();
      },
    });
  },
  async beforeMount() {
    const res = await EnvelopesService.getExtendedEnvelope(
      this.$route.params.id
    );
    this.documents = res.data.documents;
    if (res.data.recipients.length > 0) {
      const r = res.data.recipients.map((data) => {
        const recipient = data;
        recipient.inServer = true;
        recipient.changeRequestedActionWarned = false;
        recipient.requestedAction = recipient.type;
        recipient.authType = recipient.signature_type;
        recipient.name = '';
        recipient.email = '';
        if (recipient.addressees.length > 0) {
          recipient.name = recipient.addressees[0].name;
          if (recipient.addressees[0].via === 'whatsapp') {
            recipient.phone = recipient.addressees[0].value;
          } else {
            recipient.email = recipient.addressees[0].value;
          }
          const recipientPhoto = recipient.addressees[0].identifiers
            .filter((i) => i.code === 'PHOTO')
            .shift();
          recipient.photo = recipientPhoto ? recipientPhoto.is_required : '';
        }
        recipient.shippingType = recipient.phone ? 'whatsapp' : 'email';
        return recipient;
      });
      this.recipients = await this.recipients.concat(r);

      await this.recipients.sort((a, b) => {
        return a.routing_order - b.routing_order;
      });
      const emptyRecipientsCount = this.recipients.reduce(
        (count, recipient) => {
          if (recipient.name === '') {
            return count + 1;
          }
          return count;
        },
        0
      );
      this.hasEmptyRecipients = emptyRecipientsCount;
      this.$nextTick(() => {
        this.recipientsOrder.ordered = true;
        if (!res.data.recipients[0].routing_order)
          this.recipientsOrder.ordered = false;
      });
    }
  },
  mounted() {
    if (!this.getOnboarding('business_envelope_recipient')) {
      this.$refs.onboardingRecipientModal.$refs.modal.open();
    }
  },
  computed: {
    ...mapGetters({ getOnboarding: 'onboardings/getOnboarding' }),
    hasAtLeastOneSigner() {
      return this.recipients.some(
        (recipient) => recipient.requestedAction === RecipientType.SIGNER
      );
    },
    hasRecipient() {
      return this.recipients.length > 0;
    },
    recipientsCopy() {
      return this.recipients.slice();
    },
    hasEnvelopeFields() {
      return this.documents.reduce((fieldsCount, document) => {
        return fieldsCount + document.fields.length;
      }, 0);
    },
    hasFeatureWhatsapp() {
      const feature = this.$store.state.subscription.plan.plan.features
        .filter((i) => i.feature === 'whatsapp')
        .shift();
      if (!feature) {
        return false;
      }
      return !!feature.feature;
    },
    shippingTitle() {
      return this.hasFeatureWhatsapp
        ? 'Enviar envelope via*:'
        : 'Endereço de e-mail*';
    },
  },
  methods: {
    ...mapActions({ updateOnboarding: 'onboardings/updateOnboarding' }),
    async validateForm() {
      const resultValidate = await this.$refs.form.validate();
      return resultValidate;
    },
    async addEmptyRecipient() {
      this.addRecipient('', '');
    },
    async addUserAsRecipient() {
      this.addRecipient(
        this.$root.user.name,
        this.$root.user.email,
        this.$root.user.phone
      );
    },
    async addContactsAsRecipient(contacts) {
      contacts.forEach((contact) => {
        this.addRecipient(
          contact.name,
          contact.email,
          contact.phone,
          contact.id
        );
      });
    },
    addRecipient(name, email, phone, contactId) {
      const recipient = { ...recipientModel };
      recipient.id = Math.floor(Math.random() * 10000);
      recipient.name = name;
      recipient.email = email;
      recipient.phone = phone;
      recipient.inServer = false;
      recipient.shippingType =
        this.hasFeatureWhatsapp && phone ? 'whatsapp' : 'email';
      recipient.requestedAction = this.typeOfRecipients[0].id;
      recipient.authType = this.typeOfAuth[0].id;
      recipient.contactId = contactId;
      this.recipients.push(recipient);
    },
    removeRecipientConfirmation(recipient) {
      if (!this.hasEnvelopeFieldsByRecipient(recipient)) {
        this.removeRecipient(recipient);
        return;
      }
      this.selectedRecipientRemove = recipient;
      this.$refs.deleteRecipientModal.$refs.modal.open();
    },
    async removeRecipient(recipient) {
      if (recipient.inServer) {
        await EnvelopesService.removeRecipient(
          this.$route.params.id,
          recipient.id
        );
      }
      this.recipients = this.recipients.filter(
        (item) => item.id !== recipient.id
      );
    },
    validateRecipients() {
      return this.recipients.find(
        (recipient) => recipient.requestedAction === RecipientType.SIGNER
      );
    },
    addRecipientOnEnvelope(recipient, order) {
      return new Promise((resolve, reject) => {
        if (recipient.requestedAction !== 'signer') {
          recipient.photo = false;
        }
        const shippingTypeValue =
          recipient.shippingType === 'email'
            ? recipient.email
            : recipient.phone;
        const data = {
          type: recipient.requestedAction,
          addressees: [
            {
              via: recipient.shippingType,
              value: shippingTypeValue,
              name: recipient.name,
              identifiers: [
                {
                  code: 'PHOTO',
                  is_required: recipient.photo,
                },
              ],
            },
          ],
        };
        data.signature_type = recipient.authType;
        if (this.recipientsOrder.ordered) {
          data.routing_order = order;
        }
        if (
          recipient.updated &&
          this.$root.user.email != recipient.email &&
          recipient.contactId
        ) {
          ContactService.update(recipient.contactId, {
            name: recipient.name,
            email: recipient.email,
            phone: recipient.phone,
          });
        }
        EnvelopesService.addRecipient(this.$route.params.id, data)
          .then(() => {
            const r = recipient;
            r.inServer = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateRecipientOnEnvelope(recipient, order) {
      return new Promise((resolve, reject) => {
        if (recipient.requestedAction !== 'signer') {
          recipient.photo = false;
        }
        const data = {
          type: recipient.requestedAction,
          addressees: [
            {
              via: recipient.shippingType,
              value:
                recipient.shippingType === 'email'
                  ? recipient.email
                  : recipient.phone,
              name: recipient.name,
              identifiers: [
                {
                  code: 'PHOTO',
                  is_required: recipient.photo,
                },
              ],
            },
          ],
        };
        data.signature_type = recipient.authType;
        data.routing_order = null;
        if (this.recipientsOrder.ordered) {
          data.routing_order = order;
        }
        EnvelopesService.updateRecipient(
          this.$route.params.id,
          recipient.id,
          data
        )
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onClickSignatureType(recipient) {
      if (
        !this.hasEnvelopeFieldsByRecipient(recipient) ||
        recipient.changeRequestedActionWarned ||
        !recipient.inServer
      ) {
        return;
      }
      this.$refs.changeTypeSignerModal.$refs.modal.open();
      recipient.changeRequestedActionWarned = true;
    },
    async saveContact() {
      this.$loading(true);
      try {
        const promises = [];
        for (let i = 0; i < this.recipients.length; i += 1) {
          const recipient = this.recipients[i];
          if (
            (recipient.email !== '' || recipient.phone !== '') &&
            recipient.requestedAction !== ''
          ) {
            if (!recipient.inServer) {
              promises.push(this.addRecipientOnEnvelope(recipient, i + 1));
              continue;
            }

            if (recipient.updated || this.recipientsOrder.updated) {
              promises.push(this.updateRecipientOnEnvelope(recipient, i + 1));
            }
          }
        }

        await Promise.all(promises);
      } finally {
        this.$loading(false);
      }
    },
    async saveAndClose() {
      await this.saveContact();
      if (this.nextRoute) {
        this.nextRoute();
        return;
      }
      this.$router.push({ name: 'business.documents.draft' });
    },
    async nextStep() {
      if (await this.validateForm()) {
        this.error = false;
        const invalidRecipients = this.validateRecipients();
        if (invalidRecipients === undefined) {
          this.$refs.noSubscriberModal.$refs.modal.open();
          return false;
        }
        await this.saveContact();
        this.$router.replace({
          name: 'business.envelope.fields',
          params: {
            id: this.$route.params.id,
            isNew: this.$route.params.isNew,
          },
        });
      }
      return true;
    },
    discardChanges() {
      if (this.nextRoute) {
        this.nextRoute();
        return;
      }
      this.$router.go(-1);
    },
    inputDisabled(recipient) {
      if (
        recipient.email === this.$root.user.email &&
        recipient.name === this.$root.user.name
      ) {
        return true;
      }

      return false;
    },
    hasEnvelopeFieldsByRecipient(recipient) {
      if (!recipient.inServer) {
        return false;
      }
      let fieldsLength = this.documents.reduce((fieldsCount, document) => {
        return (
          fieldsCount +
          document.fields.reduce((fieldsCountPerDocument, field) => {
            if (field.recipient === recipient.id) {
              return fieldsCountPerDocument + 1;
            }
            return fieldsCountPerDocument;
          }, fieldsCount)
        );
      }, 0);
      return fieldsLength > 0;
    },
  },
};
</script>
<style lang="stylus" scoped>

.card-body
  label
    font-size: 1rem
.alert-template
  color: #01698E
  background-color: #CCEFFB
  height: 3.125rem
  font-weight: bold
  border-radius: 0.313rem
.w-center
  margin: auto
  max-width: 960px
.recipient-order
  position: absolute
  left: -5rem
  top: 0
  bottom: 0
  margin: auto
  background-color: #fff
  width: 4rem
  height: 4rem
  border: 2px solid #f2f2f2
  text-align: center
  line-height: 3.7rem
  border-radius: 50%
  padding: 0em
  font-size: 1.8em
  font-weight: 600
  color: #c3c3c3
  cursor: move
.form-check.form-switch
  display: flex
  padding-left: 0
  .form-check-input
    width: 2.438rem
    &:focus
      box-shadow: none
.link--delete-recipient
  font-size: 1rem
  color: #555555
  .default
    display: inline
  .hover
    display: none
  &:hover
    .default
      display: none
    .hover
      display: inline
.text-danger
  font-size: 1rem
  padding-top: 0.25rem
.order-recipient
  border-top-left-radius: 0.5rem
  border-bottom-left-radius: 0.5rem
  width: 1.625rem
  background-color: #E5EBED
  color: #013D52
  font-size: 0.875rem
  text-align: center
  .icon
    font-size: 1rem
  .text
    font-weight: 600
    margin-top: 5.8rem
.moving-card
  opacity: 0.5
.dropdown-stylized--with-border
  .icon.arrow
    margin: auto 0.875rem !important
    height: auto
  span
    font-weight: 600
</style>
